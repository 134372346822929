import React from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import { useGetCountry } from '../../api/commonApi';
import { useUpdateProfileDetails } from '../../api/profileApi';
import CustomModal from '../UI/CustomModal';
import CustomSelect from '../UI/CustomSelect/CustomSelect';

import { schema } from './schema';
import { Container } from './style';

const CountrySelectModal = ({ onSave }) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { data: { data: countryList = [] } = {} } = useGetCountry();
  const country = countryList.map((c) => ({
    label: c,
    value: c,
    url: `${process.env.REACT_APP_API_URL_BASE}/public/flags/${c}.svg`,
  }));

  const updateProfile = useUpdateProfileDetails();

  const handleCountryChange = async (credentials) => {
    updateProfile.mutateAsync({ ...credentials }).then(onSave);
  };

  return (
    <CustomModal closeIcon={null} footer={null} open={true} width={433}>
      <Container>
        <h1>Select Country</h1>
        <Controller
          control={control}
          name="country"
          render={({ field: { onChange, value } }) => (
            <CustomSelect
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              label={t('Select Country')}
              message={errors?.country?.message}
              onChange={onChange}
              optionFilterProp="children"
              options={country}
              placeholder={t('Select your country')}
              showSearch
              status={errors?.country ? 'error' : ''}
              value={value}
            />
          )}
          rules={schema.country}
        />

        <Button loading={updateProfile.isPending} onClick={handleSubmit(handleCountryChange)} type="primary">
          {t('Submit')}
        </Button>
      </Container>
    </CustomModal>
  );
};

export default CountrySelectModal;

/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import { useLogin } from '../../api/authApi';
import CustomInput from '../../Components/UI/CustomInput/CustomInput';
import NavigationLink from '../../Components/UI/NavigationLink/NavigationLink';
import GoogleLoginSVG from '../../Components/UI/SVG-Icons/GoogleLoginSVG';
import { useOAuthContext } from '../../context/OAuthContext';
import useTranslatedData from '../../hooks/useTranslatedData';
import { PublicWrapper } from '../../styles/style';
import routes from '../../utils/routes';
import { setInLocalStore } from '../../utils/utils';

import './style.scss';
import { loginSchema } from './schema/schema';

const Login = () => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef(null);
  // const [rememberMe, setRememberMe] = useState(getFromLocalStore('rememberMe'));
  const [rememberMe, setRememberMe] = useState(true);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { googleClientId, facebookClientId, onGoogleError, onGoogleSuccess, handleFacebookFailure, responseFacebook } =
    useOAuthContext();

  const login = useLogin();

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
    const currentPos = inputRef?.current?.input?.selectionStart; // Get cursor position
    setTimeout(() => {
      inputRef?.current?.input?.setSelectionRange(currentPos, currentPos); // Restore cursor position
    }, 0);
  };

  const handleLoginUser = async (data) => {
    login.mutate(data);
  };

  const handleRememberMe = () => {
    setRememberMe(rememberMe === true ? false : true);
    setInLocalStore('rememberMe', rememberMe === true ? false : true);
  };

  const loginSchemaTranslation = useTranslatedData(loginSchema);

  // useEffect(() => {
  //   if (rememberMe === true) {
  //     useLoginStore.persist.setOptions({
  //       storage: createJSONStorage(() => localStorage),
  //     });
  //   } else {
  //     useLoginStore.persist.setOptions({
  //       storage: createJSONStorage(() => sessionStorage),
  //     });
  //   }
  // }, [rememberMe]);

  return (
    <PublicWrapper>
      <Helmet>
        <title>{t('Login')}</title>
      </Helmet>
      <div className="auth-page">
        <div className="auth-page__title">{t('Sign in your registered account or register for the first time.')}</div>
        <div className="auth-page__form">
          <div className="auth-page__form__page-indicator">
            <NavigationLink customClass="active" to="#">
              {t('Login')}
            </NavigationLink>
            <NavigationLink to={routes.REGISTRATION}>{t('Registration')}</NavigationLink>
          </div>
          <div className="form">
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => (
                <CustomInput
                  label={t('Email')}
                  message={errors?.email?.message}
                  onChange={onChange}
                  placeholder={t('Enter your email')}
                  status={errors.email ? 'error' : ''}
                  value={value}
                />
              )}
              rules={loginSchemaTranslation.email}
            />
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value } }) => (
                <CustomInput
                  label={t('Password')}
                  message={errors?.password?.message}
                  onChange={onChange}
                  placeholder={t('Enter your password')}
                  ref={inputRef}
                  status={errors?.password ? 'error' : ''}
                  suffix={
                    <div onClick={handleShowPassword}>{!showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}</div>
                  }
                  type={showPassword ? 'text' : 'password'}
                  value={value}
                />
              )}
              rules={loginSchemaTranslation.password}
            />
            <div className="forgot-password__line">
              {/* <Checkbox className="remebmer-me" checked={rememberMe === true} onClick={handleRememberMe}>
                {t('Remember')}
              </Checkbox> */}
              <NavigationLink customClass="forgot-password" to={routes.FORGOT}>
                {t('forgot password')}
              </NavigationLink>
            </div>
            {login?.error ? <p className="validation-error__text">{t(login?.error?.response?.data?.message)}</p> : null}
            <Button
              className="login-user__btn mb-3"
              loading={login.isPending}
              onClick={handleSubmit(handleLoginUser)}
              type="primary"
            >
              {t('Login')}
            </Button>
          </div>
          <div className="sign-in-via__line">{t('Sign in via')}</div>
          <div className="sign-via__buttons">
            {/* <div className="auth__btn">
              <FacebookLogin
                appId={facebookClientId}
                autoLoad={false}
                textButton=""
                cssClass="kep-login-facebook kep-login-facebook-31"
                fields="name,email,picture"
                callback={responseFacebook}
                onFailure={handleFacebookFailure}
                icon={<FacebookLoginSVG />}
                size="small"
              />
            </div> */}
            <div className="auth__btn">
              <GoogleOAuthProvider clientId={googleClientId}>
                <GoogleLogin
                  height="40"
                  icon={<GoogleLoginSVG />}
                  logo_alignment="center"
                  onError={onGoogleError}
                  onSuccess={onGoogleSuccess}
                  shape="rectangular"
                  size="large"
                  theme="filled_black"
                  type="icon"
                  width="40"
                />
              </GoogleOAuthProvider>
            </div>
          </div>
        </div>
      </div>
    </PublicWrapper>
  );
};

export default Login;

import React from 'react';

import { Link } from 'react-router-dom';

import cn from 'classnames';
import './style.scss';

const NavigationLink = ({ to = '', customClass, target = '_self', children }) => {
  return (
    <Link className={cn('nav-link', { [customClass]: customClass })} target={target} to={to}>
      {children}
    </Link>
  );
};

export default NavigationLink;

import React, { useMemo, useState } from 'react';

import './style.scss';
import { Form } from 'antd';

import { StyledSelect, SuffixIcon } from './style';

const CustomSelect = ({
  label = '',
  placeholder = 'Select',
  value,
  style,
  status = 'success',
  message,
  options,
  formClassName = '',
  fullWidth,
  sort = true,
  ...props
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const selectedImageUrl = useMemo(() => {
    return !isMenuOpen ? options?.find((op) => op?.value === value)?.url : null;
  }, [options, isMenuOpen, value]);
  return (
    <Form
      className={`modal-form__input ${formClassName}`}
      style={{ ...style, width: fullWidth ? '100%' : style?.width || 'auto' }}
    >
      {label ? <label className="modal-form__input-label ">{label}</label> : null}
      <Form.Item help={message} validateStatus={status}>
        <StyledSelect
          {...props}
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
          filterSort={(optionA, optionB) =>
            sort ? (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase()) : null
          }
          onDropdownVisibleChange={setIsMenuOpen}
          optionFilterProp="children"
          optionRender={(option) => {
            if (option.data.emoji) {
              return (
                <>
                  <span className="item-img">{option.data.emoji}</span>
                  <div aria-label={option.data.label} />
                  {option.data.label}
                </>
              );
            }
            if (option.data.url) {
              return (
                <>
                  <img alt="img" src={option.data.url} />
                  <div aria-label={option.data.label} />
                  {option.data.label}
                </>
              );
            } else {
              return (
                <>
                  <div aria-label={option.data.label} />
                  {option.data.label}
                </>
              );
            }
          }}
          options={options}
          placeholder={placeholder}
          popupClassName="customSelect"
          showSuffix={!!selectedImageUrl}
          suffixIcon={selectedImageUrl ? <SuffixIcon src={selectedImageUrl} /> : <></>}
          value={value}
        />
      </Form.Item>
    </Form>
  );
};

export default CustomSelect;

import { useQuery } from '@tanstack/react-query';

import openNotification from '../utils/openNotification';

import { LEADER_BOARD_API } from './constant/constant';
import { getApi } from './utils/utils';

export const useGetUsersLeaderBoard = () => {
  return useQuery({
    queryKey: ['leaderboard', 'users'],
    queryFn: () => {
      return getApi(LEADER_BOARD_API.GET_USERS);
    },
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

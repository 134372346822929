import { useNavigate } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { useTimerStore } from '../Pages/AuthPages/utils.js/useTimerStore';
import { resetAll } from '../store/clearStore';
import { useUserStore } from '../store/useUserStore';
import openNotification from '../utils/openNotification';
import routes from '../utils/routes';
import { useTranslatedNotification } from '../utils/utils';

import { AUTHENTICATION, PROFILE_API } from './constant/constant';
import { getApi, postApi, putApi, setHeaderToken } from './utils/utils';

export const useLogin = () => {
  const navigate = useNavigate();
  const userDetails = useUserStore();

  return useMutation({
    mutationKey: 'user_details',
    mutationFn: (data) => {
      return postApi(AUTHENTICATION.LOGIN, data);
    },
    onSuccess: (data) => {
      userDetails.updateUser(data?.data?.user);
      navigate(routes.OTP_VERIFY);
    },
  });
};

export const useRegister = ({ onSuccess }) => {
  const navigate = useNavigate();
  const userDetails = useUserStore();

  return useMutation({
    mutationKey: 'user_details',
    mutationFn: (data) => {
      return postApi(AUTHENTICATION.REGISTER, data);
    },
    onSuccess: (data) => {
      userDetails.updateUser(data?.data?.user);
      navigate({ pathname: routes.OTP_VERIFY }, { state: { type: 'signup' } });

      if (_.isFunction(onSuccess)) {
        onSuccess();
      }
    },
  });
};

export const useVerifyOtp = (language) => {
  const navigate = useNavigate();
  const loginStore = useUserStore();
  const timer = useTimerStore();

  return useMutation({
    mutationFn: (data) => {
      return postApi(AUTHENTICATION.VERIFY_OTP, data);
    },
    onSuccess: (data) => {
      setHeaderToken(data?.data?.accessToken, data?.data?.refreshToken);
      loginStore.addToken(data?.data);
      putApi(PROFILE_API.UPDATE_PROFILE, { language }, true);
      navigate(routes.PROFILE);
      timer.reset();
    },
    onError: () => {},
  });
};

export const useVerifyEmail = () => {
  const navigate = useNavigate();
  const timer = useTimerStore();
  const loginStore = useUserStore();
  const { showNotification } = useTranslatedNotification();

  return useMutation({
    mutationFn: (data) => {
      return postApi(AUTHENTICATION.VERIFY_EMAIL, data);
    },
    onSuccess: (data) => {
      showNotification('success', 'Email verified successfully');
      setHeaderToken(data?.data?.accessToken, data?.data?.refreshToken);
      loginStore.addToken(data?.data);
      navigate(routes.PROFILE);
      timer.reset();
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useResendOtp = () => {
  const userDetails = useUserStore();

  return useQuery({
    queryKey: ['resend_otp'],
    queryFn: () => {
      return getApi(`/user/auth/resend-otp/${userDetails?.user?.id}`);
    },
    enabled: false,
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useForgotPassword = () => {
  return useMutation({
    mutationFn: (data) => {
      return postApi('/user/auth/forgot-password', data);
    },
    onSuccess: () => {},
    onError: () => {},
  });
};

export const useRestorePassword = () => {
  const navigate = useNavigate();
  const { showNotification } = useTranslatedNotification();

  return useMutation({
    mutationFn: (data) => {
      return putApi(AUTHENTICATION.RESTORE, {
        ...data,
      });
    },
    onSuccess: () => {
      showNotification('success', 'Password restored successfully');
      navigate(routes.LOGIN);
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useResetPassword = () => {
  const userDetails = useUserStore();
  const { showNotification } = useTranslatedNotification();

  return useMutation({
    mutationFn: (data) => {
      return putApi('/user/auth/reset-password', {
        userId: userDetails?.user?.id,
        ...data,
      });
    },
    onSuccess: () => {
      showNotification('success', 'Password changed successfully');
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useManualLogout = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return function () {
    resetAll();
    queryClient.clear();
    navigate(routes.LOGIN);
  };
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      return postApi(AUTHENTICATION.LOGOUT);
    },
    onSuccess: () => {
      resetAll();
      queryClient.clear();
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useLoginWithGoogle = () => {
  const navigate = useNavigate();
  const timer = useTimerStore();
  const loginStore = useUserStore();
  const { showNotification } = useTranslatedNotification();

  return useMutation({
    mutationFn: (data) => {
      return postApi(AUTHENTICATION.GOOGLE_LOGIN, data);
    },
    onSuccess: (data) => {
      showNotification('success', 'Login successfully');
      setHeaderToken(data?.data?.accessToken, data?.data?.refreshToken);
      loginStore.addToken(data?.data);
      navigate(routes.PROFILE);
      timer.reset();
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useLoginWithFacebook = () => {
  const navigate = useNavigate();
  const timer = useTimerStore();
  const loginStore = useUserStore();
  const { showNotification } = useTranslatedNotification();

  return useMutation({
    mutationFn: (data) => {
      return postApi(AUTHENTICATION.FACEBOOK_LOGIN, data);
    },
    onSuccess: (data) => {
      showNotification('success', 'Login successfully');
      setHeaderToken(data?.data?.accessToken, data?.data?.refreshToken);
      loginStore.addToken(data?.data);
      navigate(routes.PROFILE);
      timer.reset();
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

import React, { useCallback, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import './style.scss';
import { useForgotPassword } from '../../api/authApi';
import { ReactComponent as DoneSvg } from '../../assets/icons/Done.svg';
import CustomInput from '../../Components/UI/CustomInput/CustomInput';
import NavigationLink from '../../Components/UI/NavigationLink/NavigationLink';
import useTranslatedData from '../../hooks/useTranslatedData';
import { PublicWrapper } from '../../styles/style';
import routes from '../../utils/routes';

import { forgotPasswordSchema } from './schema/schema';
import { ThankyouWrapper } from './style';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [thankYou, setThankYou] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const forgotPassword = useForgotPassword();

  const handleForgotPassword = async (data) => {
    try {
      await forgotPassword.mutateAsync(data);
      setThankYou(true);
    } catch (err) {}
  };

  const ThankYouMessage = useCallback(() => {
    return (
      <ThankyouWrapper>
        <DoneSvg />
        {t('Please check your Email. We have sent you Password Reset steps.')}
      </ThankyouWrapper>
    );
  }, []);

  const forgotPasswordSchemaTranslation = useTranslatedData(forgotPasswordSchema);

  return (
    <PublicWrapper>
      <div className="auth-page">
        <h2 className="auth-page__title title">{thankYou ? t('Thank you') : t('Forgot Password')}</h2>
        <div className="auth-page__form">
          <div className="form">
            <div className="card__container">
              {thankYou ? (
                <ThankYouMessage />
              ) : (
                <>
                  <div className="card__title">
                    {t(
                      'To reset your password, kindly provide the email address used during your account registration.',
                    )}
                  </div>
                  <Controller
                    control={control}
                    name="email"
                    render={({ field: { onChange, value } }) => (
                      <CustomInput
                        label={t('Email')}
                        message={errors?.email?.message}
                        onChange={onChange}
                        placeholder={t('Enter your email to reset')}
                        status={errors?.email ? 'error' : ''}
                        value={value}
                      />
                    )}
                    rules={forgotPasswordSchemaTranslation.email}
                  />
                  {forgotPassword?.error ? (
                    <p className="validation-error__text">{forgotPassword?.error?.response?.data?.message}</p>
                  ) : null}
                  <Button
                    className="login-user__btn mb-3 card__submit"
                    loading={forgotPassword.isPending}
                    onClick={handleSubmit(handleForgotPassword)}
                    type="primary"
                  >
                    {t('Confirm email')}
                  </Button>
                  <Button className="card__link" type="link">
                    <NavigationLink to={routes.LOGIN}>{t('Back To Login Page')}</NavigationLink>
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </PublicWrapper>
  );
};

export default ForgotPassword;

import { useMutation, useQuery } from '@tanstack/react-query';

import openNotification from '../utils/openNotification';
import { getQueryStringFromObj } from '../utils/utils';

import { PUBLIC_API, affiliateLink } from './constant/constant';
import { getApi, postApi } from './utils/utils';

export const useGetTimeZones = () => {
  return useQuery({
    queryKey: ['timezone'],
    queryFn: () => {
      return getApi(PUBLIC_API.TIMEZONE);
    },
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useGetLanguages = () => {
  return useQuery({
    queryKey: ['language'],
    queryFn: () => {
      return getApi(PUBLIC_API.LANGUAGE);
    },
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useGetCurrency = () => {
  return useQuery({
    queryKey: ['currency'],
    queryFn: () => {
      return getApi(PUBLIC_API.CURRENCY);
    },
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useGetCountry = () => {
  return useQuery({
    queryKey: ['country'],
    queryFn: () => {
      return getApi(PUBLIC_API.COUNTRY);
    },
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useContactForm = () => {
  return useMutation({
    mutationKey: ['contact_us', 'send'],
    mutationFn: (data) => {
      return postApi('/public/guest/send', data, true);
    },
  });
};

export const useLinkClicked = () => {
  return useMutation({
    mutationKey: ['link_clicked'],
    mutationFn: (data) => {
      let getQueryString = getQueryStringFromObj(data);
      return fetch(affiliateLink + '/public/link-clicked' + getQueryString);
    },
  });
};

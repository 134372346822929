import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const initialState = {
  signupParams: {},
};

export const useSessionStorage = create(
  persist(
    (set) => ({
      ...initialState,
      setSignUpParams: (params) => set({ signupParams: params }),
    }),
    {
      name: 'tempStore',
      // storage: createJSONStorage(() => {
      //   return getAsyncStorage();
      // }),
      storage: createJSONStorage(() => {
        return sessionStorage;
      }),
    },
  ),
);

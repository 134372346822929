import styled from 'styled-components';

import { ResponseIcon } from '../../../constants/image';

export const SupportDetailContainer = styled.div`
  padding: 20px 20px 10px 20px;
  .title {
    font-size: 1.25rem;
    margin-bottom: 12px;
    span {
      margin-right: 5px;
      cursor: pointer;
    }

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  .sub_title {
    width: 100%;
    font-size: 1.125rem;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 480px) {
      flex-direction: column;

      span {
        margin-right: 5px;
        cursor: pointer;
      }
    }

    > div {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 480px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .date-time_label {
      font-size: 14px;
      text-align: end;

      @media screen and (max-width: 480px) {
        font-size: 12px;
      }
    }

    .tag {
      margin-left: 15px;
      margin-inline-end: 0px !important;
    }
  }
`;

export const SupportMessageContainer = styled.div`
  padding: 10px 20px;
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;

  tr .ant-table-cell {
    border: none !important;
  }
  .ant-table-tbody .ant-table-cell {
    padding: 8px 16px !important;
  }

  .ant-table-tbody tr:last-child .ant-table-cell {
    padding-bottom: 20px !important;
  }

  .ant-table-thead > tr > th {
    border-top: 1px solid var(--color-border-dark) !important;
  }

  .ant-table-cell-row-hover {
    background-color: transparent !important;
  }

  .single_msg {
    gap: 30px;
    display: inline-flex;
    max-width: 90%;
    width: 100%;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }
  .message_box {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    width: 100%;
    position: relative;

    .text {
      max-width: 70%;
      word-break: break-all;
    }

    .label {
      width: 82px;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12px;
      text-align: center;
      background-image: url(${ResponseIcon});
    }
  }
  .msg {
    padding: 15px;
    border-radius: 5px;
    position: relative;
    width: 100%;
  }

  .user-msg {
    background-color: var(--color-grey-6);
  }
  .admin-msg {
    background-color: var(--color-grey-1);
  }

  @media screen and (max-width: 768px) {
    .ant-pagination {
      justify-content: center !important;
    }

    tr:first-child .ant-table-cell {
      padding-top: 20px !important;
    }

    tr:not(:last-child) .ant-table-cell {
      padding-bottom: 2px;
    }

    .ant-table-tbody tr:last-child .ant-table-cell {
      padding-bottom: 20px !important;
    }

    .ant-btn-primary {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    .ant-btn-primary {
      width: 100% !important;
    }
  }
`;

export const MobileViewWrapper = styled.div`
  /* padding: 10px 0px; */

  .message_box {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    width: 100%;
    position: relative;

    .text {
      max-width: 100%;
      word-break: break-all;
    }

    .label {
      width: 82px;
      position: absolute;
      right: 0px;
      top: 75%;
      transform: translateY(-75%);
      font-size: 12px;
      text-align: center;
      background-image: url(${ResponseIcon});
    }
  }
  .msg {
    padding: 15px;
    margin-top: 10px;
    border-radius: 5px;
    position: relative;
    width: 100%;
  }

  .user-msg {
    background-color: var(--color-grey-6);
  }
  .admin-msg {
    background-color: var(--color-grey-1);
    padding-bottom: 45px;
  }
  .ant-btn {
    margin-top: 5px;
    height: auto;
    float: right;
  }
`;

import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useFAQ } from '../../api/publicApi';
import FaqTabItem from '../../Components/FaqTabItem/FaqTabItem';
import CustomCollapse from '../../Components/UI/CustomCollapse/CustomCollapse';
import './style.scss';

const FaqPage = () => {
  const { t } = useTranslation();
  const { pathname, hash, key } = useLocation();
  const panelStyle = {
    marginBottom: 24,
    fontSize: 16,
    border: '1px solid #3A405B',
    borderRadius: '5px',
  };
  const { data: { data } = {}, isLoading } = useFAQ();

  const fetchCategoryData = (category, data = []) => {
    return data
      .filter((d) => d.category === category)
      .map((d) => {
        return {
          ...d,
          label: d.question,
          // eslint-disable-next-line react/no-danger
          children: <div dangerouslySetInnerHTML={{ __html: d.answer }} />,
          style: panelStyle,
        };
      });
  };

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  const generateQuestions = fetchCategoryData('General', data);
  const financialQuestions = fetchCategoryData('Financial', data);
  const registrationAndVerificationQuestions = fetchCategoryData('Verification', data);

  return (
    <div className="faq-page-container">
      <Helmet>
        <title>{t('FAQ')}</title>
      </Helmet>
      <div className="faq-page">
        <h1 className="faq-page__title">{t('FAQ')}</h1>
        <div className="question-tab-items">
          {[
            { label: t('General Questions'), count: generateQuestions.length, id: 'general' },
            { label: t('Financial Questions'), count: financialQuestions.length, id: 'financial' },
            { label: t('Verification'), count: registrationAndVerificationQuestions.length, id: 'verification' },
          ].map((item, index) => (
            <FaqTabItem
              count={item.count}
              id={item.id}
              index={index}
              key={item.label}
              loading={isLoading}
              title={item.label}
            />
          ))}
        </div>
        <div className="questions__wrapper general" id="general">
          <h2 className="questions__wrapper__title">{t('General Questions')}</h2>
          <CustomCollapse items={generateQuestions || []} loading={isLoading} />
        </div>
        <div className="questions__wrapper financial" id="financial">
          <h2 className="questions__wrapper__title">{t('Financial Questions')}</h2>
          <CustomCollapse items={financialQuestions || []} loading={isLoading} />
        </div>
        <div className="questions__wrapper verification" id="verification">
          <h2 className="questions__wrapper__title">{t('Registration and Verification')}</h2>
          <CustomCollapse items={registrationAndVerificationQuestions || []} loading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default FaqPage;

import { Button } from 'antd';

import { DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';

import { useChartContext } from '../../../../context/ChartContext';
import useHandleOverlay from '../../../../hooks/useHandleOverlay';
import { ChartToolsOptions } from '../../../../klinechart/constant';

import { OverlayToolMenuContainer, OverlayToolMenuItem } from './style';

const OverlayToolMenu = () => {
  const { removeOverlay } = useHandleOverlay();
  const { selectedOverlay } = useChartContext();

  return selectedOverlay ? (
    <OverlayToolMenuContainer>
      <OverlayToolMenuItem>
        <div className="tool_title">
          {ChartToolsOptions.find((tool) => tool.type === selectedOverlay.name)?.label ||
            _.startCase(selectedOverlay.name)}
        </div>
        <Button
          danger
          icon={<DeleteOutlined style={{ width: 14, height: 14 }} />}
          onClick={() => {
            removeOverlay(selectedOverlay.id);
          }}
          type="text"
        />
      </OverlayToolMenuItem>
    </OverlayToolMenuContainer>
  ) : (
    <></>
  );
};

export default OverlayToolMenu;

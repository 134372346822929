import { Modal } from 'antd';

import styled from 'styled-components';

const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: 0px;

    .ant-modal-header {
      padding: 20px 24px;
      border-bottom: 1px solid var(--color-border-dark);
      margin-bottom: 0px;

      .ant-modal-title {
        font-weight: 400;
      }
    }

    .ant-modal-body {
      padding: 24px;
      /* max-height: 70vh; */
      overflow: auto;
      position: relative;

      @media screen and (max-width: 768px) {
        max-height: none;
      }
    }
    .ant-modal-footer {
      border-top: 1px solid var(--color-border-dark);
      padding: 20px 24px;
      display: flex;
      justify-content: center;
      border-radius: 0px 0px 8px 8px;
    }
  }
`;

export default CustomModal;

import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as Mute } from '../../assets/icons/Mute.svg';
import { ReactComponent as Unmute } from '../../assets/icons/Unmute.svg';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { useUserStore } from '../../store/useUserStore';
import routes, { hashRoutes } from '../../utils/routes';
import Backdrop from '../UI/Backdrop/Backdrop';
import AirplaneSVG from '../UI/SVG-Icons/SidebarSVG/AirplaneSVG';
import ChartSVG from '../UI/SVG-Icons/SidebarSVG/ChartSVG';
import CloseSVG from '../UI/SVG-Icons/SidebarSVG/CloseSVG';
import CupSVG from '../UI/SVG-Icons/SidebarSVG/CupSVG';
import DemoSVG from '../UI/SVG-Icons/SidebarSVG/DemoSVG';
import SettingsSVG from '../UI/SVG-Icons/SidebarSVG/SettingsSVG';
import SupportSVG from '../UI/SVG-Icons/SidebarSVG/SupportSVG';
import UserSVG from '../UI/SVG-Icons/SidebarSVG/UserSVG';
import './style.scss';

const Sidebar = ({ setIsSidebarOpen = () => {} }) => {
  const sidebarRef = useRef();
  const { notificationAlert, setNotificationAlert } = useUserStore();
  const location = useLocation();
  const isTab = useMediaQuery(769);
  const { t } = useTranslation();
  // const toggleSidebard = () => {
  //   sidebarRef.current.classList.toggle('open');
  // };

  const handleNotificationAlert = () => {
    setNotificationAlert(!notificationAlert);
  };

  useOnClickOutside(sidebarRef, () => setIsSidebarOpen(false));

  return (
    <>
      <div className="app__sidebar sidebar" ref={sidebarRef}>
        <nav className="sidebar__navigations">
          {isTab ? (
            <div className="close_item" onClick={() => setIsSidebarOpen(false)}>
              <CloseSVG />
            </div>
          ) : null}
          <NavLink className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')} to={routes.TERMINAL.HOME}>
            <ChartSVG />
            {t('Live')}
          </NavLink>
          <NavLink className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')} to={routes.DEMO_TRADE}>
            <DemoSVG />
            {t('Demo')}
          </NavLink>
          <NavLink
            className={() => (location.pathname.includes('/support') ? 'nav-item active' : 'nav-item')}
            to="/support/my-request"
          >
            <SupportSVG />
            {t('Support')}
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}
            to={routes.PROFILE.HOME + routes.PROFILE.ACCOUNT}
          >
            <UserSVG />
            {t('Profile')}
          </NavLink>
          <NavLink
            className={() => (`${location?.hash}` === hashRoutes.LEADER_BOARD ? 'nav-item' : 'nav-item')}
            to={hashRoutes.LEADER_BOARD}
          >
            <CupSVG />
            {t('Leaders')}
          </NavLink>
          <NavLink
            className={() => (`${location?.hash}` === hashRoutes.SETTINGS ? 'nav-item' : 'nav-item')}
            to={hashRoutes.SETTINGS}
          >
            <SettingsSVG />
            {t('Settings')}
          </NavLink>
        </nav>
        <div className="sidebar__footer">
          <div className="sidebar__footer__nav-item" onClick={handleNotificationAlert}>
            {!notificationAlert ? <Mute /> : <Unmute />}
            {!notificationAlert ? `${t('Mute')}` : `${t('Unmute')}`}
          </div>
          <NavLink className="sidebar__footer__nav-item" target="_blank" to="https://t.me/MartexTradeOfficial">
            <AirplaneSVG />
            {t('Join')}
          </NavLink>
        </div>
      </div>
      <Backdrop />
    </>
  );
};

export default React.memo(Sidebar);

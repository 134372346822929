const TABOOLA_ID = 1826258;
const isTaboolaEnabled = process.env.REACT_APP_ENV === 'production';

const useTaboola = () => {
  const registerEvent = () => {
    if (isTaboolaEnabled) {
      window._tfa.push({
        notify: 'event',
        name: 'complete_registration',
        id: TABOOLA_ID,
      });
    }
  };

  return {
    registerEvent,
  };
};

export default useTaboola;

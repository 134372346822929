import randomNumbers from './random-numbers';

// function calculateEMA(values, period = 9) {
//   const smoothingFactor = 2 / (period + 1);

//   let ema = values[0];

//   for (let i = 1; i < values.length; i += 1) {
//     ema = (values[i] - ema) * smoothingFactor + ema;
//   }

//   return ema;
// }

// function calculateTrendIndicator(ema9, ema50, currentClose, referencePoint, avgMovement) {
//   let difference;

//   if (currentClose >= ema9 && currentClose >= ema50) {
//     difference = referencePoint - currentClose;
//   } else if (currentClose <= ema9 && currentClose <= ema50) {
//     difference = referencePoint - currentClose;
//   } else {
//     difference = currentClose - referencePoint;
//   }

//   const percentage =
//     difference > 0 ? Math.min(95, (difference * 100) / avgMovement) : Math.max(-95, (difference * 100) / avgMovement);

//   const trend = Math.floor(percentage / 2);

//   const trendPercentage = trend + 50;

//   // eslint-disable-next-line no-console
//   // console.table([{ ema9, ema50, difference, currentClose, trendPercentage }]);

//   return trendPercentage;
// }

// export const getIndicatorPercentage = (last50Candles) => {
//   console.log(last50Candles.at(-1))
//   const last9Candles = last50Candles.slice(-9);
//   const last5Candles = last50Candles.slice(-5);
//   const avgMovement = last9Candles.reduce((acc, el) => acc + Math.abs(el.close - el.open), 0) / last9Candles.length;

//   const pair9Ema = calculateEMA(last9Candles.map((chunk) => chunk.close));
//   const pair5Ema = calculateEMA(last5Candles.map((chunk) => chunk.close));
//   const pair50Ema = calculateEMA(
//     last50Candles.map((chunk) => chunk.close),
//     50,
//   );

//   const trendPercentage = calculateTrendIndicator(
//     pair9Ema,
//     pair50Ema,
//     last9Candles.at(-1)?.close,
//     pair5Ema,
//     avgMovement * 1.5,
//   );

//   return { profit: trendPercentage || 0, loss: 100 - (trendPercentage || 0) };
// };

export const getIndicatorPercentage = (id) => {
  if (!id || !window.chartEnd) {
    return { profit: 30, loss: 70 };
  }

  const arrayToUse = id % 6;
  const startPoint = (id % 20) * 10;

  // console.log(id + ' ' + arrayToUse + ' ' + startPoint);

  const index = (Math.floor(window.chartEnd / 1000) % randomNumbers[arrayToUse].length) + startPoint;

  const profit = randomNumbers[arrayToUse].at(index % randomNumbers[arrayToUse].length);

  return { profit: profit, loss: 100 - profit };
};

import { useTranslation } from 'react-i18next';

import modern1IC from '../assets/icons/modern1.svg';
import modern2IC from '../assets/icons/modern2.svg';
import modern3IC from '../assets/icons/modern3.svg';
import modern4IC from '../assets/icons/modern4.svg';
import cap1 from '../assets/images/cap-1.png';
import cap2 from '../assets/images/cap-2.png';
import cap3 from '../assets/images/cap-3.png';
import cap4 from '../assets/images/cap-4.png';
import step1 from '../assets/images/step-1.png';
import step2 from '../assets/images/step-2.png';
import step3 from '../assets/images/step-3.png';

const useTranslatedConstant = () => {
  const { t } = useTranslation();

  const FAQS_ITEM_1 = [
    {
      id: 1,
      question: t('How to learn?'),
      answer: t('Sign up and train on a demo account. It is exactly the same as real trading, but for free.'),
    },
    {
      id: 2,
      question: t('How long does it take to withdraw funds?'),
      answer: t(
        'On average, the withdrawal procedure takes from one to five days from the date of receipt of the corresponding request of the Client and depends only on the volume of simultaneously processed requests. The company always tries to make payments directly on the day the request is received from the Client.',
      ),
    },
    {
      id: 3,
      question: t('What is a trading platform and why is it needed?'),
      answer: t(
        'Trading platform - a software complex that allows the Client to conduct trades (operations) using different financial instruments. It has also accesses to various information such as the value of quotations, real-time market positions, actions of the Company, etc.',
      ),
    },
  ];

  const FAQS_ITEM_2 = [
    {
      id: 4,
      question: t('Can I trade with the phone?'),
      answer: t(
        'Our platform runs on the most modern technology and opens in the browser of any computer or mobile phone.',
      ),
    },
    {
      id: 5,
      question: t('What is the minimum deposit amount?'),
      answer: t(
        'The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is 10 US dollars.',
      ),
    },
    {
      id: 6,
      question: t('Is there any fee for depositing or withdrawing funds from the account?'),
      answer: t(
        'No. The company does not charge any fee for either the deposit or for the withdrawal operations.However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.',
      ),
    },
  ];

  const MODERN_ITEMS = [
    {
      icon: modern1IC,
      text: t(
        "This trading platform has transformed my approach to investing. The user-friendly interface and real-time analytics empower me to make informed decisions. It's not just a platform; it's a game-changer in the world of finance.",
      ),
    },
    {
      icon: modern2IC,
      text: t(
        "I love the flexibility this platform offers. Whether I`m at home or on the go, I can manage my trades seamlessly with the mobile app. It's the perfect companion for the modern investor who values convenience.",
      ),
    },
    {
      icon: modern3IC,
      text: t(
        "The real-time data and advanced analytics on this platform are unparalleled. I can analyze market trends, make strategic decisions, and execute trades with confidence. It's a data-driven investor's dream come true.",
      ),
    },
    {
      icon: modern4IC,
      text: t(
        "Being able to connect with other traders and follow their strategies has added a new dimension to my trading experience. I've learned so much and enhanced my portfolio by leveraging collective wisdom ",
      ),
    },
  ];

  const CAPABILITIES_ITEMS = [
    {
      title: t('Convenient trading interface'),
      text: t(
        'We created the most simple and comfortable interface that does not distract from the main thing - from trading',
      ),
      bgSRC: cap1,
      icon: modern1IC,
    },
    {
      title: t('Integrated signals'),
      text: t(
        'Approach the strategy thoughtfully - the most precise and innovative signals with an accuracy of 87% will help you create your own effective strategy ',
      ),
      bgSRC: cap2,
      icon: modern2IC,
    },
    {
      title: t('Trading indicators'),
      text: t('We have gathered the most useful trading indicators. Use them to boost your account balance'),
      bgSRC: cap3,
      icon: modern3IC,
    },
    {
      title: t('Perfect speed'),
      text: t('Our platform runs on the most modern technology and delivers incredible speed'),
      bgSRC: cap4,
      icon: modern4IC,
    },
  ];

  const STEPS_ITEM = [
    {
      title: t('Join Us'),
      description: t('Get started by creating your free account.Start trading within minutes.'),
      bgSRC: step1,
      buttonText: t('Join Us Now to Begin Trading.'),
    },
    {
      title: t('Practise & Grow'),
      description: t('Perfect your skills with a demo account and expert training materials.'),
      bgSRC: step2,
      buttonText: t('Boost your confidence with practice.'),
    },
    {
      title: t('Start Earning'),
      description: t('Explore over high performing trading options. Begin with just $5 for profitable trading.'),
      bgSRC: step3,
      buttonText: t('Deposit and start earning now.'),
    },
  ];

  return {
    FAQS_ITEM_1,
    FAQS_ITEM_2,
    MODERN_ITEMS,
    STEPS_ITEM,
    CAPABILITIES_ITEMS,
  };
};

export default useTranslatedConstant;

import React from 'react';

import { useTranslation } from 'react-i18next';

import LaunchingSoonImage from '../../assets/images/launchingSoon.svg';
import CustomAnnouncement from '../../Components/UI/CustomAnnouncement/CustomAnnouncement';

import { Container } from './style';

const LaunchingSoon = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <CustomAnnouncement
        header={t('Launching Soon')}
        image={LaunchingSoonImage}
        open={true}
        text={t(
          'The countdown has begun, and we can not wait to share it with you. Stay tuned for updates and be the first to witness something truly remarkable!',
        )}
      />
    </Container>
  );
};

export default LaunchingSoon;

import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from 'antd';

import { useResendOtp, useVerifyEmail, useVerifyOtp } from '../../api/authApi';
import { useUserStore } from '../../store/useUserStore';
import { PublicWrapper } from '../../styles/style';
import { getLanguageName, secondsToMMSS } from '../../utils/utils';

import { timerInSeconds, useTimerStore } from './utils.js/useTimerStore';

let intervalId;

const OTPVerification = () => {
  const { t, i18n } = useTranslation();
  const { language } = useUserStore();
  const [otp, setOTP] = useState(['', '', '', '', '', '']);
  const inputRefs = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];

  const resendOTP = useResendOtp();
  const verifyOTP = useVerifyOtp(getLanguageName(i18n.language));
  const verifyEmail = useVerifyEmail();
  const userDetails = useUserStore();
  const timer = useTimerStore();
  const location = useLocation();

  const counter = useRef(timer.timer);

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (value.length <= 1) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);

      // focus to the next input field if the current one is filled
      if (value !== '' && index < 5) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    const newOTP = [...otp];

    for (let i = 0; i < pastedData.length && index + i < 6; i++) {
      newOTP[index + i] = pastedData[i];
    }

    setOTP(newOTP);
  };
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      // focus to the previous input field when Backspace is pressed on an empty field
      inputRefs[index - 1].current.focus();
    }
  };

  const handleVerifyOtp = () => {
    if (location.state?.type === 'signup') {
      verifyEmail.mutate({ otp: otp.join(''), userId: userDetails?.user?.id, language });
    } else {
      verifyOTP.mutate({ otp: otp.join(''), userId: userDetails?.user?.id, language });
    }
  };

  const handleResendOtp = () => {
    resendOTP.refetch();
    resetTimer();
  };

  const resetTimer = () => {
    counter.current = timerInSeconds;
    timer.reset();
    startTimer();
  };

  const startTimer = () => {
    intervalId = setInterval(() => {
      if (counter.current <= 0) {
        clearInterval(intervalId);
      } else {
        counter.current = counter.current - 1;
      }

      timer.setTime(counter.current);
    }, 1000);
  };

  useEffect(() => {
    resetTimer();

    useUserStore.persist.rehydrate();

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <PublicWrapper>
      <div className="auth-page">
        <h2 className="auth-page__title title">{t('OTP Verification')}</h2>
        <div className="auth-page__form">
          <div className="form">
            <div className="card__container">
              <div className="card__title">
                {t('Kindly input the verification code that has been sent to your registered email.')}
                <div className="otp__timer">
                  {t('The code will expire in')} <span>{secondsToMMSS(counter.current)}</span>
                </div>
                {counter.current === 0 ? (
                  <Button className="card__link" loading={resendOTP.isFetching} onClick={handleResendOtp} type="link">
                    {t('Resend OTP')}
                  </Button>
                ) : null}
              </div>

              <div className="auth-page__otp">
                <div className="auth-page__otp__inputs-block">
                  {otp.map((character, index) => (
                    <input
                      className="otp__input"
                      key={index}
                      maxLength="1"
                      onChange={(e) => handleChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onPaste={(e) => handlePaste(e, index)}
                      ref={inputRefs[index]}
                      type="number"
                      value={character}
                    />
                  ))}
                </div>
                {verifyOTP?.error ? (
                  <p className="validation-error__text">{verifyOTP?.error?.response?.data?.message}</p>
                ) : null}
                <Button
                  className="login-user__btn"
                  disabled={otp.filter((o) => o).length !== 6}
                  loading={location.state?.type === 'signup' ? verifyEmail?.isPending : verifyOTP?.isPending}
                  onClick={handleVerifyOtp}
                  type="primary"
                >
                  {t('Submit')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PublicWrapper>
  );
};

export default OTPVerification;

import React from 'react';

import moment from 'moment';

import { useSocketContext } from '../context/SocketContext';
import { useGlobalStore } from '../store/useGlobalStore';
import { useTrade } from '../store/useTrade';
import { getCandleTimestamps } from '../utils/utils';

import { useGetSetData } from './useGetSetData';
import useTimeZone from './useTimeZone';

const useReactQuerySubscription = ({ pair, timeFrame }, addNewData = () => {}) => {
  const { socket } = useSocketContext();
  const { activeTrades } = useGlobalStore();
  const { investmentTimeType, investmentTime } = useTrade();
  const { getCurrentTimeZone } = useTimeZone();

  React.useEffect(() => {
    socket.on('cas', (newData) => {
      addNewData(pair, newData);
    });
    return () => {
      socket.off('cas');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pair, timeFrame, JSON.stringify(activeTrades), investmentTimeType, getCurrentTimeZone, investmentTime]); //intended to include activeTrades
  //all the places where cas is listern should have same dependencies

  return { socket };
};

export const createActiveTradeLabel = (
  id,
  index,
  type,
  investment,
  startTime,
  endTime,
  targetTime,
  value,
  targetCandleEnd,
) => {
  const time = targetTime - moment(endTime).startOf('second').valueOf();
  if (time > 0) {
    if (window.chartRef.getOverlayById(id)) {
      return window.chartRef.overrideOverlay({
        id: id,
        extendData: {
          type,
          activeTradeIndex: index,
          label: `${investment} - ${moment().startOf('day').milliseconds(time).format('HH:mm:ss')}`,
          targetCandleEnd,
        },
      });
    }

    window.chartRef.createOverlay({
      id: id,
      name: 'SegmentTag',
      extendData: {
        type,
        activeTradeIndex: index,
        label: `${investment} - ${moment().startOf('day').milliseconds(time).format('HH:mm:ss')}`,
        targetCandleEnd,
      },
      points: [{ timestamp: startTime, value: value }],
      mode: 'strong_magnet',
      modeSensitivity: 2,
      zLevel: 6 - index,
    });
  }
};

export const useShowLabelsForActiveTrades = ({ pair, timeFrame }) => {
  const { socket } = useSocketContext();
  const { removeTrade } = useTrade();
  const { removeActiveTrade } = useGlobalStore();
  const { getActivePairId } = useGetSetData();
  const { activeTrades } = useGlobalStore();
  const { investmentTimeType, investmentTime } = useTrade();
  const { getCurrentTimeZone } = useTimeZone();

  React.useEffect(() => {
    socket.on('cas', (newData) => {
      if (pair === newData?.pair) {
        window.cas = newData;
      }

      if (activeTrades?.length) {
        activeTrades?.forEach(({ type, investment, startTime, targetTime, value, id }, index, self) => {
          const targetCandleEnd = getCandleTimestamps(targetTime, timeFrame);
          const startCandle = getCandleTimestamps(startTime, timeFrame);

          if (pair === newData?.pair && targetTime >= newData?.end) {
            return createActiveTradeLabel(
              id,
              self.length - index - 1,
              type,
              investment,
              startCandle?.candleStart,
              newData?.end,
              targetTime,
              value,
              targetCandleEnd?.candleEnd,
            );
          }
          if (targetTime <= newData?.end) {
            removeTrade(getActivePairId, id);
            removeActiveTrade(getActivePairId, id);
            window.chartRef.removeOverlay({ id: id });
          }
        });
      }
    });
    //below 30 seconds it needs to refreshed as time will change
    return () => {
      socket.off('cas');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pair,
    JSON.stringify(timeFrame),
    JSON.stringify(activeTrades),
    investmentTimeType,
    getCurrentTimeZone,
    investmentTime,
  ]);
  //TODO: make common place to listen cas events

  React.useEffect(() => {
    return () => {
      activeTrades?.forEach(({ id }) => {
        window.chartRef.removeOverlay({ id: id });
      });
    };
  }, [timeFrame]);
};

export default useReactQuerySubscription;

import styled from 'styled-components';

export const ChartContainer = styled.div`
  margin-top: 5px;
`;

export const ChartWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.nativeHeight ? '97vh' : 'calc(100dvh - 160px)')};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 375px;

  #chart {
    width: 100%;
    height: 100%;
    display: flex;
  }

  #chart_container {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .chart__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 768px) {
    height: ${(props) => (props.nativeHeight ? '97vh' : 'calc(100dvh - 290px)')};
  }
`;

export const ChartPredictionIndicator = styled.div`
  width: 4px;
  margin-right: 15px;
  gap: 5px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-size: 10px;

  .text {
    left: -5px;
    position: relative;
  }

  .top-section,
  .bottom-section {
    width: 100%;
    transition: height 1s ease; /* Smooth animation for height changes */
  }

  .top-section {
    background-color: var(--color-chart-red); /* Top section color */
    height: ${({ loss }) => `${loss}%`}; /* Dynamic height for top section */
    border-radius: 5px 5px 0 0;
  }

  .bottom-section {
    background-color: var(--color-chart-green); /* Bottom section color */
    height: ${({ profit }) => `${profit}%`}; /* Dynamic height for bottom section */
    border-radius: 0 0 5px 5px;
  }
`;

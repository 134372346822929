import { Card } from 'antd';

import styled from 'styled-components';

export const NotificationCard = styled(Card)`
  margin-bottom: 10px;
  cursor: pointer;

  .ant-card-body {
    padding: 10px 25px 20px 10px;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(-50%);
    border: 1px solid var(--color-border-dark);
  }

  .active {
    background-color: var(--color-red);
    border-color: var(--color-red);
  }

  .ant-avatar {
    min-width: 40px;
    height: 40px;
  }

  .notification__container {
    display: flex;
    gap: 10px;

    img {
      margin-top: 10px;
      border-radius: 10px;
      height: 200px;
    }

    &-information {
      width: 100%;
      .title {
        margin-bottom: 7px;
        display: flex;
        justify-content: space-between;

        p {
          min-width: 20%;
          max-width: 70%;
          @media screen and (max-width: 768px) {
            max-width: 60%;
          }

          @media screen and (max-width: 480px) {
            max-width: 50%;
          }
        }

        .dateTime {
          color: var(--color-text-secondary);
          text-align: right;
        }
      }
      .description {
        min-width: 70%;
        max-width: 80%;
      }
    }
  }
`;

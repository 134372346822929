import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Row } from 'antd';

import NotFoundImage from '../../assets/images/404NotFound.svg';
import CustomAnnouncement from '../../Components/UI/CustomAnnouncement/CustomAnnouncement';
import routes from '../../utils/routes';

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Row justify="center" style={{ margin: 'auto' }}>
      <CustomAnnouncement.StaticAnnouncement
        actionButton={() => navigate(routes.HOME)}
        header={t('The requested page could not be found!')}
        img={NotFoundImage}
        text={t('Go to home page')}
      />
    </Row>
  );
};

export default NotFound;

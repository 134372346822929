import React from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Col, Row } from 'antd';

import Logo from '../../assets/icons/logo-white font.svg';
import { PUBLIC_LINKS } from '../../constants/links.js';
import routes from '../../utils/routes';
import NavigationLink from '../UI/NavigationLink/NavigationLink';
import FacebookSVG from '../UI/SVG-Icons/FacebookSVG';
import InstagramSVG from '../UI/SVG-Icons/InstagramSVG';
import AirplaneSVG from '../UI/SVG-Icons/SidebarSVG/AirplaneSVG';
import TwitterSVG from '../UI/SVG-Icons/TwitterSVG';
import './style.js';

import { CopyWrightContainer, FooterContainer } from './style.js';

const PublicFooter = () => {
  const { t } = useTranslation();

  return (
    <>
      <FooterContainer>
        <div className="wrapper">
          <div className="top" />
          <Row className="navigation" gutter={[16, 16]}>
            <Col className="navigation__links" lg={6} md={8} sm={8} xl={6} xs={24}>
              <ul>
                <li>
                  <div className="logo">
                    <img alt="logo" src={Logo} />
                  </div>
                  <div className="address">
                    <p className="text">
                      MartexTrade
                      <br /> Reg. No: 2023-000672 <br /> Ground Floor, The Sotheby Building, <br /> Saint Lucia P.O. Box
                      838
                    </p>
                  </div>
                  <div className="social-media hide-mobile">
                    {/* <YoutubeSVG /> */}
                    {/* <AirplaneSVG/> */}
                    <NavLink className="svg-link" target="_blank" to="https://t.me/MartexTradeOfficial">
                      <AirplaneSVG />
                    </NavLink>
                    <FacebookSVG />
                    <InstagramSVG />
                    <TwitterSVG />
                  </div>
                </li>
              </ul>
            </Col>
            <Col className="navigation__links" lg={6} md={8} sm={8} xl={6} xs={24}>
              <ul>
                <li>
                  <NavigationLink
                    customClass="footer__link"
                    target="_blank"
                    to={process.env.REACT_APP_AFFILIATE_WEBSITE}
                  >
                    {t('Affiliates')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink customClass="footer__link" to={routes.REGISTRATION}>
                    {t('Sign up')}
                  </NavigationLink>
                </li>
              </ul>
            </Col>
            <Col className="navigation__links" lg={6} md={8} sm={8} xl={6} xs={24}>
              <ul>
                <li>
                  <NavigationLink customClass="footer__link" to="#">
                    {t('Quick Links')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink customClass="footer__link" to={routes.ABOUT_US}>
                    {t('About us')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink customClass="footer__link" to={routes.CONTACT_US}>
                    {t('Contact us')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink customClass="footer__link" to={routes.FAQ}>
                    {t('FAQ')}
                  </NavigationLink>
                </li>
              </ul>
            </Col>
            <Col className="navigation__links" lg={6} md={24} sm={24} xl={6} xs={24}>
              <ul>
                <li>
                  <NavigationLink customClass="footer__link" to="#">
                    {t('Regulations')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink customClass="footer__link" target="_blank" to={routes.PRIVACY_POLICY}>
                    {t('Privacy policy')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink customClass="footer__link" target="_blank" to={PUBLIC_LINKS.PAYMENT_POLICY}>
                    {t('Payment policy')}
                  </NavigationLink>{' '}
                </li>
                <li>
                  <NavigationLink customClass="footer__link" target="_blank" to={PUBLIC_LINKS.SERVICE_AGREEMENT}>
                    {t('Service agreement')}
                  </NavigationLink>{' '}
                </li>
                <li>
                  <NavigationLink customClass="footer__link" target="_blank" to={PUBLIC_LINKS.RISK_DISCLOSURE}>
                    {t('Risk disclosure')}
                  </NavigationLink>{' '}
                </li>
                <li>
                  <NavigationLink customClass="footer__link" target="_blank" to={PUBLIC_LINKS.TRADING_OPERATION_RULE}>
                    {t('Rules of trading operations')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink customClass="footer__link" target="_blank" to={PUBLIC_LINKS.NON_TRADING}>
                    {t('Non-trading operations regulations')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink customClass="footer__link" target="_blank" to={PUBLIC_LINKS.KYC_POLICY}>
                    {t('KYC Policy')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink customClass="footer__link" target="_blank" to={PUBLIC_LINKS.AML_POLICY}>
                    {t('AML Policy')}
                  </NavigationLink>
                </li>
              </ul>
            </Col>
          </Row>
          <div className="top">
            <div className="social-media show-mobile">
              {/* <YoutubeSVG /> */}
              <NavLink className="svg-link" target="_blank" to="https://t.me/MartexTradeOfficial">
                <AirplaneSVG />
              </NavLink>
              <FacebookSVG />
              <InstagramSVG />
              <TwitterSVG />
            </div>
          </div>
          <p className="riskMsg">
            <b>{t('Risk Warning')}</b>{' '}
            {t(
              'MartexTrade and leveraged financial instruments carry significant risk, possibly leading to capital loss. Only invest what you can afford to lose. Trading leveraged products may not be suitable for everyone. Even non-leveraged products like stocks involve risks, with the value fluctuating. Past performance doesnt guarantee future results. Consider your experience and investment goals before trading, and seek independent financial advice if needed. Clients must verify if using MartexTrade services complies with legal requirements in their residence country.',
            )}
          </p>
        </div>
      </FooterContainer>
      <CopyWrightContainer>{t('Copyright © 2023-24 MartexTrade. All rights reserved')}</CopyWrightContainer>
    </>
  );
};

export default PublicFooter;

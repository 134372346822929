import React from 'react';

import { useTranslation } from 'react-i18next';

import MaintenanceImage from '../../assets/images/demodesktop.jpeg';
import CustomAnnouncement from '../../Components/UI/CustomAnnouncement/CustomAnnouncement';

import { Container } from './style';

const UnderMaintenance = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <CustomAnnouncement
        header={t('Under Maintenance')}
        image={MaintenanceImage}
        open={true}
        text={t(
          'Maintenance in progress! We are making upgrades to serve you better. Thank you for your understanding and patience during this time.',
        )}
      />
    </Container>
  );
};

export default UnderMaintenance;

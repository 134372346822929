import React, { useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Button, Checkbox } from 'antd';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import _ from 'lodash';

import { useRegister } from '../../api/authApi';
import { useGetCountry } from '../../api/commonApi';
import CustomInput from '../../Components/UI/CustomInput/CustomInput';
import CustomSelect from '../../Components/UI/CustomSelect/CustomSelect';
import NavigationLink from '../../Components/UI/NavigationLink/NavigationLink';
import GoogleLoginSVG from '../../Components/UI/SVG-Icons/GoogleLoginSVG';
import { PUBLIC_LINKS } from '../../constants/links';
import { useOAuthContext } from '../../context/OAuthContext';
import useTranslatedData from '../../hooks/useTranslatedData';
import { useSessionStorage } from '../../store/useSessionStorage';
import { useUserStore } from '../../store/useUserStore';
import { PublicWrapper } from '../../styles/style';
import useTaboola from '../../tracking/useTaboola';
import routes from '../../utils/routes';

import { registerSchema } from './schema/schema';

import './style.scss';

const Registration = () => {
  const { t } = useTranslation();
  const { registerEvent } = useTaboola();
  const [showPassword, setShowPassword] = useState(false);
  const [isAdult, setIsAdult] = useState(false);
  const { data: { data: countryList = [] } = {} } = useGetCountry();
  const { signupParams = {} } = useSessionStorage();
  const { language } = useUserStore();
  const inputRef = useRef(null);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { googleClientId, onGoogleError, onGoogleSuccess } = useOAuthContext();

  const register = useRegister({ onSuccess: registerEvent });

  const handleRegisterUser = async (credentials) => {
    register.mutate({ ...credentials, ...(!_.isEmpty(signupParams) && signupParams), language });
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
    const currentPos = inputRef?.current?.input?.selectionStart; // Get cursor position
    setTimeout(() => {
      inputRef?.current?.input?.setSelectionRange(currentPos, currentPos); // Restore cursor position
    }, 0);
  };

  const country = countryList.map((c) => ({
    label: c,
    value: c,
    // emoji: c.symbol,
    // url: c.file,
  }));

  useEffect(() => {
    if (register.isSuccess) {
      reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register.isSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const registerSchemaTranslatedData = useTranslatedData(registerSchema);

  return (
    <PublicWrapper>
      <Helmet>
        <title>{t('Sign up')}</title>
      </Helmet>
      <div className="auth-page">
        <div className="auth-page__title title">{t('Get Onboard')}</div>
        <div className="auth-page__form">
          <div className="auth-page__form__page-indicator">
            <NavigationLink to={routes.LOGIN}>{t('Login')}</NavigationLink>
            <NavigationLink customClass="active" to={routes.REGISTRATION}>
              {t('Registration')}
            </NavigationLink>
          </div>
          <div className="form">
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => (
                <CustomInput
                  label={t('Email')}
                  message={errors?.email?.message}
                  onChange={onChange}
                  placeholder={t('Enter your email')}
                  status={errors?.email ? 'error' : ''}
                  value={value}
                />
              )}
              rules={registerSchemaTranslatedData.email}
            />

            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value } }) => (
                <CustomInput
                  label={t('Password')}
                  message={errors?.password?.message}
                  onChange={onChange}
                  placeholder={t('Enter your password')}
                  ref={inputRef}
                  status={errors?.password ? 'error' : ''}
                  suffix={
                    <div onClick={handleShowPassword}>{!showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}</div>
                  }
                  type={showPassword ? 'text' : 'password'}
                  value={value}
                />
              )}
              rules={registerSchemaTranslatedData.password}
            />

            <Controller
              control={control}
              name="country"
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  label={t('Country')}
                  message={errors?.country?.message}
                  onChange={onChange}
                  optionFilterProp="children"
                  options={country}
                  placeholder={t('Select your country')}
                  showSearch
                  status={errors?.country ? 'error' : ''}
                  value={value}
                />
              )}
              rules={registerSchemaTranslatedData.country}
            />

            <div className="agreement__line">
              <div className="checkbox-block">
                <Checkbox
                  checked={isAdult}
                  className="remebmer-me"
                  onClick={() => {
                    setIsAdult((a) => !a);
                  }}
                />
                <p className="checkbox-label">
                  {t('I confirm that I am 18 years old or older and accept')}{' '}
                  <NavLink target="_blank" to={PUBLIC_LINKS.SERVICE_AGREEMENT}>
                    {t('Service Agreement')}
                  </NavLink>
                </p>
              </div>
            </div>
            {register?.error ? (
              <p className="validation-error__text">{register?.error?.response?.data?.message}</p>
            ) : null}
            <Button
              className="login-user__btn mb-3"
              disabled={!isAdult}
              loading={register?.isPending}
              onClick={handleSubmit(handleRegisterUser)}
              type="primary"
            >
              {t('Registration')}
            </Button>
          </div>

          <div className="sign-in-via__line">{t('Sign in via')}</div>
          <div className="sign-via__buttons">
            {/* <div className="auth__btn">
              <FacebookLogin
                appId={facebookClientId}
                autoLoad={false}
                textButton=""
                cssClass="kep-login-facebook kep-login-facebook-31"
                fields="name"
                callback={responseFacebook}
                onFailure={handleFacebookFailure}
                icon={<FacebookLoginSVG />}
                size="small"
              />
            </div> */}
            <div className="auth__btn">
              <GoogleOAuthProvider clientId={googleClientId}>
                <GoogleLogin
                  height="40"
                  icon={<GoogleLoginSVG />}
                  logo_alignment="center"
                  onError={onGoogleError}
                  onSuccess={(data) => onGoogleSuccess(data, signupParams)}
                  shape="rectangular"
                  size="large"
                  theme="filled_black"
                  type="icon"
                  width="40"
                />
              </GoogleOAuthProvider>
            </div>
          </div>
        </div>
      </div>
    </PublicWrapper>
  );
};

export default Registration;

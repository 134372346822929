import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { apiURL } from '../constant/constant';

import { refreshAuth } from './refresh-auth';

export const client = axios.create({
  baseURL: apiURL,
});

export const setHeaderToken = (token, refreshToken) => {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
  client.defaults.headers['Refresh-Token'] = refreshToken;
};

export const removeHeaderToken = () => {
  delete client.defaults.headers.common.Authorization;
  delete client.defaults.headers['Refresh-Token'];
};

createAuthRefreshInterceptor(client, refreshAuth, {
  statusCodes: [401], // default: [ 401 ]
  pauseInstanceWhileRefreshing: true,
});

export const getApi = (url, configObj = {}) => {
  const config = {
    ...configObj,
  };
  return client.get(apiURL + url, config);
};

export const postApi = (url, data = {}, multiPart = false) => {
  const config = {
    headers: {},
  };

  if (multiPart) {
    config.headers = { ...config.headers, 'Content-Type': 'multipart/form-data' };
  }

  return client.post(
    apiURL + url,
    {
      ...data,
    },
    config,
  );
};

export const putApi = (url, data, multiPart = false) => {
  const config = {
    headers: {},
  };

  if (multiPart) {
    config.headers = { ...config.headers, 'Content-Type': 'multipart/form-data' };
  }
  return client.put(apiURL + url, data, config);
};

export const deleteApi = (url, data) => {
  return client.delete(apiURL + url, { data: data });
};

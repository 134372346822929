import { Empty, Spin } from 'antd';

import styled from 'styled-components';

export const EmptyContainer = styled(Empty)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-block: 0 !important;

  .ant-empty-description {
    color: var(--color-text);
  }
`;

export const Spinner = styled(Spin)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: 26px;
    height: calc(100% - 26px);
  }
  @media screen and (min-width: 769px) {
    height: 100%;
  }
`;

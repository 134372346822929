import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Col, Row } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import _ from 'lodash';

import { useGetProfileDetails, useUpdateProfileDetails } from '../../api/profileApi';
import { ReactComponent as MoonIcon } from '../../assets/icons/moon.svg';
import { ReactComponent as SunIcon } from '../../assets/icons/sun.svg';
import LanguageSelect from '../../Components/LanguageSelect/LanguageSelect';
import TimezoneSelect from '../../Components/TimezoneSelect/TimezoneSelect';
import { CustomRadio } from '../../Components/UI/CustomRadio/CustomRadio';
import { LANGUAGES } from '../../constants/language';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useUserStore } from '../../store/useUserStore';
import { useTranslatedNotification } from '../../utils/utils';

import { THEME } from './constant';
import { SettingContainer } from './style';

const Settings = () => {
  const { t, i18n } = useTranslation();
  const isTab = useMediaQuery(768);
  const navigate = useNavigate();
  const { theme, setTheme, language, setLanguage } = useUserStore();
  const [loader, setLoader] = useState(false);
  const [profileInfo, setProfileInfo] = useState({});
  const updateProfile = useUpdateProfileDetails();

  const getProfileData = useGetProfileDetails();

  const { showNotification } = useTranslatedNotification();

  useEffect(() => {
    if (getProfileData) {
      setProfileInfo(_.pick(getProfileData?.data?.data, ['timezone', 'language']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(getProfileData.data?.data)]);

  const handleChange = async (name, value) => {
    if (name === 'language') {
      i18n.changeLanguage(LANGUAGES[value]);
      setLanguage(value);
    }
    setLoader(name);
    await updateProfile.mutateAsync({ [name]: value });
    setLoader(false);
  };

  const handleCloseSettings = () => {
    navigate({ hash: null });
  };

  const handleTheme = (theme) => {
    setTheme(theme);
    showNotification('success', 'Successfully updated');
  };

  return (
    <SettingContainer>
      <div className="setting-header">
        <h3>{t('Settings')}</h3>
        {!isTab ? (
          <span onClick={handleCloseSettings}>
            <CloseOutlined />
          </span>
        ) : null}
      </div>
      <div className="settings-body">
        <Row gutter={[24, 24]}>
          <Col lg={24} md={24} sm={12} xl={24} xs={24} xxl={24}>
            <Row>
              <Col span={24}>
                <LanguageSelect
                  loading={loader === 'language'}
                  onChange={(v) => handleChange('language', v)}
                  value={language}
                />
              </Col>
              <Col span={24}>
                <TimezoneSelect
                  loading={loader === 'timezone'}
                  onChange={(v) => handleChange('timezone', v)}
                  value={profileInfo?.timezone}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={24} md={24} sm={12} xl={24} xs={24} xxl={24}>
            <Row>
              <Col span={24}>
                <label className="theme-title">{t('Theme')}</label>
                <CustomRadio
                  checked={theme === THEME.LIGHT}
                  icon={SunIcon}
                  label={t('Light Mode')}
                  onClick={() => handleTheme(THEME.LIGHT)}
                />
              </Col>
              <Col span={24}>
                <CustomRadio
                  checked={theme === THEME.DARK}
                  icon={MoonIcon}
                  label={t('Dark Mode')}
                  onClick={() => handleTheme(THEME.DARK)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </SettingContainer>
  );
};

export default Settings;

import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { PROFILE_API } from '../api/constant/constant';
import { useGetFavForexPairs } from '../api/forexPairApi';
import { useGetTradeAccountDetails } from '../api/tradeApi';
import { getApi } from '../api/utils/utils';
import { useTrade } from '../store/useTrade';
import { getImagePreviewFromResponseData } from '../utils/utils';

export const useGetSetData = () => {
  const queryClient = useQueryClient();
  const { investmentValue, setInvestmentValue } = useTrade();
  const { data: { data: { data: favList = [] } = {} } = {} } = useGetFavForexPairs({ enabled: false });
  const { data: profileDetails } = queryClient.getQueryData(['profile', 'details']) || {};
  const { data: { data: { data: tradingAccounts = [] } = {} } = {}, refetch: refetchTradingAccount } =
    useGetTradeAccountDetails({ enabled: false });

  const activeTradingAccount = tradingAccounts.find((acc) => acc.isActive);
  const liveTradingAccount = tradingAccounts.find((acc) => acc.type === 'Live');

  const { data: picUrl } = queryClient.getQueryData(['profile', 'picture']) || {};
  const profilePictureUrl = getImagePreviewFromResponseData(picUrl);
  const activePair = favList?.find((item) => item.active);

  const fetchProfileDetails = async () => {
    try {
      let { data } = await queryClient.fetchQuery({
        queryKey: ['profile', 'details'],
        queryFn: () => {
          return getApi(PROFILE_API.GET_PROFILE);
        },
      });
      if (data?.fileName) {
        queryClient.fetchQuery({
          queryKey: ['profile', 'picture'],
          queryFn: () => getApi(PROFILE_API.GET_PROFILE_PIC, { responseType: 'arraybuffer' }),
        });
      }
    } catch {}
  };

  const fetchFavForexPair = () => {
    try {
      queryClient.refetchQueries({ queryKey: ['forex', 'fav'] });
    } catch {}
  };

  const getProfilePictureUrl = () => {
    let picture = queryClient.getQueryData(['profile', 'picture']);

    if (picture?.data) {
      return queryClient.fetchQuery({
        queryKey: ['profile', 'picture'],
        queryFn: () => getApi(PROFILE_API.GET_PROFILE_PIC, { responseType: 'arraybuffer' }),
      });
    } else {
      return null;
    }
  };

  const setActiveForex = (id) => {
    if (typeof id === 'object') {
      queryClient.setQueryData(['forex', 'fav'], (old) => {
        if (old?.data?.data?.find((pair) => pair.pairId === id?.pairId)) {
          return {
            ...old,
            data: { data: old?.data?.data?.map((pair) => ({ ...pair, active: pair.pairId === id?.pairId })) },
          };
        } else {
          return {
            ...old,
            data: { data: [...old?.data?.data?.map((pair) => ({ ...pair, active: false })), id] },
          };
        }
      });
    } else {
      queryClient.setQueryData(['forex', 'fav'], (old) => {
        return { ...old, data: { data: old?.data?.data?.map((pair) => ({ ...pair, active: pair.pairId === id })) } };
      });
    }
  };

  useEffect(() => {
    if (!investmentValue || investmentValue < activeTradingAccount?.minimumTradingAmount) {
      setInvestmentValue(activeTradingAccount?.minimumTradingAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTradingAccount?.currency]);

  return {
    getForexActivePair: activePair,
    getActivePairId: activePair?.pairId,
    fetchProfileDetails,
    getProfilePictureUrl,
    profileDetails,
    profilePictureUrl,
    fetchFavForexPair,
    setActiveForex,
    activeTradingAccount,
    liveTradingAccount,
    refetchTradingAccount,
    demoAccountId: tradingAccounts.find((acc) => acc.type === 'Demo')?.id,
    liveAccountId: tradingAccounts.find((acc) => acc.type === 'Live')?.id,
  };
};

import React from 'react';

import { client } from '../../api/utils/utils';
import withLocation from '../../hoc/withLocation';
import LaunchingSoon from '../LaunchingSoon';
import UnderMaintenance from '../UnderMaintenance/UnderMaintenance';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, code: null };
  }

  componentDidMount() {
    client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 503) {
          this.setState({ hasError: true, code: 503 });
        }
        if (error.response && error.response.status === 423) {
          this.setState({ hasError: true, code: 423 });
        }
        return Promise.reject(error);
      },
    );
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ hasError: false });
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log(error, errorInfo);
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      // eslint-disable-next-line react/destructuring-assignment
      const code = this.state.code;
      // You can render any custom fallback UI
      return code === 423 ? <LaunchingSoon /> : <UnderMaintenance />;
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default withLocation(ErrorBoundary);

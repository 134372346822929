const randomNumbers = [
  [
    55, 54, 49, 54, 54, 59, 53, 63, 55, 63, 65, 72, 82, 81, 71, 71, 76, 68, 55, 56, 62, 55, 51, 48, 47, 42, 34, 35, 20,
    30, 35, 32, 22, 22, 27, 27, 32, 18, 24, 15, 15, 10, 8, 6, 18, 8, 1, 1, 9, 18, 18, 10, 13, 18, 10, 15, 22, 19, 14,
    19, 6, 20, 21, 29, 28, 37, 36, 40, 35, 33, 47, 42, 50, 59, 57, 48, 58, 71, 82, 75, 83, 78, 74, 64, 54, 62, 62, 47,
    50, 46, 39, 32, 34, 26, 11, 12, 4, 3, 1, 4, 16, 25, 31, 41, 45, 39, 46, 39, 44, 52, 41, 37, 39, 52, 58, 48, 45, 54,
    45, 46, 36, 24, 35, 33, 28, 23, 21, 23, 18, 18, 32, 27, 42, 32, 23, 14, 22, 33, 35, 23, 20, 13, 16, 11, 13, 9, 1,
    10, 1, 2, 6, 5, 13, 14, 27, 31, 41, 41, 43, 38, 50, 62, 52, 48, 48, 52, 57, 47, 59, 57, 45, 47, 53, 56, 51, 56, 58,
    65, 58, 62, 60, 56, 62, 59, 71, 76, 81, 81, 67, 67, 70, 76, 82, 67, 67, 73, 60, 57, 63, 63, 65, 65, 63, 77, 69, 79,
    67, 54, 59, 57, 57, 48, 48, 48, 54, 54, 48, 39, 29, 32, 22, 28, 33, 43, 48, 45, 47, 50, 37, 39, 41, 40, 50, 40, 39,
    47, 53, 57, 67, 65, 75, 80, 79, 66, 79, 79, 71, 73, 69, 60, 48, 49, 57, 71, 75, 77, 69, 74, 63, 59, 66, 55, 70, 80,
    83, 85, 85, 85, 86, 86, 81, 76, 66, 54, 69, 65, 55, 68, 73, 74, 59, 65, 79, 88, 88, 79, 87, 94, 85, 88, 91, 99, 91,
    89, 92, 99, 95, 99, 99, 99, 99, 99, 99, 92, 96, 99, 99, 93, 85, 76, 63, 53, 54, 52, 53, 49, 42, 47, 57, 55, 52, 41,
    49, 60, 74, 66, 58, 69, 83, 88, 90, 91, 89, 86, 83, 70, 75, 76, 71, 57, 45, 40, 35, 33, 23, 28, 25, 21, 22, 22, 31,
    31, 26, 27, 19, 15, 20, 14, 16, 22, 19, 19, 6, 1, 2, 2, 1, 1, 7, 7, 12, 15, 9, 7, 1, 6, 1, 2, 1, 4, 9, 5, 13, 15,
    18, 31, 31, 20, 17, 15, 5, 1, 5, 1, 12, 26, 15, 29, 23, 31, 19, 33, 43, 48, 53, 65, 65, 78, 75, 75, 65, 60, 59, 46,
    33, 32, 31, 26, 28, 21, 27, 23, 15, 8, 1, 1, 1, 1, 5, 4, 3, 11, 21, 16, 6, 11, 12, 7, 18, 28, 27, 17, 22, 23, 26,
    31, 36, 35, 42, 27, 21, 25, 34, 29, 27, 31, 34, 41, 31, 21, 27, 29, 22, 30, 34, 36, 48, 47, 47, 40, 37, 27, 18, 22,
    9, 14, 9, 5, 18, 24, 30, 34, 37, 23, 13, 10, 1, 1, 15, 11, 8, 13, 1, 13, 7, 1, 8, 18, 33, 42,
  ],
  [
    45, 60, 69, 72, 72, 64, 55, 58, 48, 38, 29, 34, 21, 26, 35, 31, 39, 29, 24, 22, 17, 15, 9, 12, 5, 5, 9, 7, 4, 11, 7,
    1, 10, 21, 20, 23, 17, 16, 6, 15, 27, 26, 26, 39, 54, 52, 50, 50, 50, 50, 45, 36, 21, 33, 21, 21, 21, 28, 32, 39,
    32, 43, 43, 41, 32, 22, 17, 27, 22, 14, 1, 14, 4, 1, 1, 1, 10, 24, 15, 5, 1, 16, 9, 2, 9, 15, 25, 39, 43, 51, 49,
    51, 39, 51, 51, 54, 49, 45, 35, 39, 39, 40, 36, 38, 38, 47, 42, 41, 40, 30, 22, 17, 26, 40, 33, 44, 44, 31, 45, 38,
    28, 28, 28, 35, 35, 35, 40, 36, 41, 41, 46, 49, 56, 54, 62, 65, 63, 68, 66, 57, 61, 56, 60, 69, 65, 63, 52, 49, 58,
    70, 64, 57, 45, 45, 45, 45, 50, 52, 65, 57, 45, 35, 33, 38, 33, 28, 30, 25, 30, 26, 26, 20, 20, 8, 8, 5, 12, 17, 18,
    15, 20, 26, 33, 42, 32, 42, 54, 56, 48, 48, 45, 35, 45, 37, 52, 61, 70, 56, 42, 41, 38, 38, 26, 22, 32, 29, 16, 14,
    24, 26, 29, 39, 46, 56, 60, 46, 49, 58, 62, 76, 74, 74, 75, 85, 86, 88, 87, 79, 86, 88, 99, 98, 99, 99, 99, 99, 95,
    97, 94, 84, 90, 78, 87, 74, 82, 85, 89, 81, 79, 78, 69, 79, 93, 93, 99, 93, 87, 90, 85, 80, 94, 99, 99, 94, 81, 86,
    86, 90, 85, 77, 68, 65, 66, 76, 74, 79, 75, 89, 83, 74, 71, 61, 52, 50, 49, 52, 54, 40, 34, 48, 52, 58, 59, 60, 70,
    63, 74, 76, 77, 92, 88, 99, 97, 90, 75, 87, 94, 98, 89, 79, 83, 92, 87, 85, 82, 89, 99, 89, 96, 96, 99, 99, 99, 99,
    99, 99, 99, 99, 89, 76, 81, 81, 72, 65, 59, 71, 66, 66, 56, 60, 60, 70, 80, 72, 66, 66, 71, 76, 80, 75, 85, 92, 97,
    99, 99, 99, 99, 99, 99, 91, 94, 95, 91, 88, 91, 91, 83, 86, 76, 61, 64, 55, 50, 44, 44, 29, 29, 14, 3, 7, 16, 13, 7,
    13, 4, 4, 11, 10, 22, 16, 9, 16, 12, 21, 24, 22, 22, 19, 22, 9, 15, 14, 12, 11, 18, 20, 20, 8, 3, 5, 18, 21, 22, 10,
    14, 14, 1, 1, 1, 1, 1, 1, 1, 7, 6, 1, 1, 1, 1, 1, 16, 17, 2, 1, 1, 1, 1, 6, 18, 17, 2, 1, 1, 16, 26, 27, 21, 19, 19,
    14, 9, 1, 1, 12, 9, 18, 13, 17, 22, 28, 24, 28, 15, 18, 10, 16, 16, 21, 33, 18, 33, 39, 24, 30, 28, 34, 21, 19, 17,
    11, 12, 3, 1, 7, 1, 1, 3, 3, 1, 4, 8, 18, 15, 15, 8, 9, 15, 23, 26, 36,
  ],
  [
    41, 46, 42, 43, 38, 48, 52, 52, 63, 53, 67, 61, 56, 60, 74, 79, 82, 88, 94, 99, 99, 94, 87, 81, 81, 81, 96, 99, 92,
    96, 96, 96, 99, 94, 99, 99, 95, 91, 96, 99, 99, 99, 96, 94, 94, 79, 92, 94, 99, 99, 89, 84, 80, 89, 93, 89, 87, 88,
    75, 70, 72, 65, 73, 88, 97, 99, 99, 99, 99, 97, 99, 99, 99, 97, 99, 95, 96, 87, 87, 89, 89, 77, 87, 73, 70, 77, 65,
    67, 74, 64, 54, 43, 50, 58, 58, 54, 40, 40, 47, 60, 65, 56, 50, 52, 47, 38, 42, 47, 42, 36, 44, 44, 47, 42, 33, 35,
    41, 34, 29, 38, 38, 40, 35, 36, 48, 44, 38, 37, 32, 38, 30, 30, 30, 26, 23, 11, 1, 1, 9, 22, 19, 22, 33, 46, 60, 57,
    71, 72, 67, 67, 73, 63, 76, 71, 73, 75, 72, 85, 98, 93, 84, 84, 82, 87, 83, 77, 86, 76, 77, 74, 79, 71, 65, 77, 77,
    64, 53, 54, 51, 36, 49, 62, 53, 49, 48, 61, 71, 64, 59, 69, 68, 75, 83, 73, 88, 86, 76, 64, 77, 82, 81, 76, 91, 93,
    85, 90, 93, 99, 99, 98, 94, 94, 99, 99, 99, 99, 96, 83, 96, 99, 88, 78, 89, 83, 86, 85, 77, 87, 77, 69, 69, 66, 56,
    51, 44, 37, 47, 49, 39, 34, 29, 38, 41, 40, 55, 55, 52, 63, 56, 50, 54, 49, 49, 63, 57, 64, 57, 48, 56, 41, 52, 43,
    43, 53, 57, 67, 72, 85, 83, 89, 77, 87, 88, 82, 81, 74, 74, 79, 74, 79, 74, 71, 77, 75, 70, 55, 57, 61, 52, 42, 55,
    48, 35, 35, 27, 35, 32, 35, 45, 56, 53, 51, 55, 50, 43, 38, 32, 29, 30, 40, 40, 45, 50, 36, 41, 39, 39, 43, 58, 66,
    61, 71, 83, 95, 99, 85, 73, 63, 52, 46, 51, 61, 61, 74, 76, 88, 83, 89, 87, 92, 89, 84, 87, 84, 86, 82, 83, 89, 83,
    71, 73, 74, 77, 67, 73, 77, 87, 86, 86, 89, 77, 79, 73, 58, 53, 38, 27, 38, 50, 47, 40, 40, 45, 55, 47, 32, 18, 16,
    26, 34, 44, 49, 36, 41, 43, 40, 51, 59, 62, 53, 45, 39, 40, 42, 40, 27, 27, 13, 17, 16, 22, 10, 9, 18, 32, 37, 40,
    36, 32, 23, 28, 27, 20, 14, 1, 1, 15, 19, 24, 29, 25, 27, 28, 32, 32, 44, 40, 32, 33, 38, 45, 50, 44, 46, 47, 43,
    54, 63, 54, 53, 58, 58, 45, 40, 40, 48, 43, 41, 32, 40, 44, 39, 42, 32, 47, 36, 30, 16, 28, 23, 9, 13, 11, 11, 1, 1,
    2, 6, 4, 7, 2, 15, 10, 1, 1, 1, 1, 4, 1, 6, 9, 3, 1, 6, 14, 15, 16, 14, 2, 7, 11, 15, 16, 9, 12, 9, 19, 15, 26, 35,
  ],
  [
    85, 92, 95, 95, 99, 99, 92, 87, 90, 99, 94, 99, 96, 98, 99, 95, 99, 99, 99, 95, 99, 99, 91, 86, 89, 75, 85, 89, 97,
    99, 97, 91, 95, 93, 99, 98, 99, 99, 98, 99, 99, 93, 93, 99, 99, 98, 88, 79, 87, 89, 98, 99, 99, 99, 99, 99, 93, 96,
    94, 94, 90, 89, 97, 99, 97, 94, 97, 99, 99, 98, 99, 90, 90, 98, 99, 99, 94, 92, 80, 77, 72, 69, 74, 79, 69, 76, 71,
    82, 84, 69, 66, 70, 69, 69, 61, 58, 58, 57, 67, 80, 72, 72, 64, 58, 53, 65, 50, 43, 44, 30, 37, 32, 22, 30, 31, 29,
    28, 24, 24, 10, 7, 1, 14, 21, 18, 8, 2, 1, 1, 11, 15, 29, 23, 26, 26, 36, 32, 35, 20, 22, 33, 24, 24, 26, 25, 20,
    10, 8, 1, 1, 9, 17, 24, 25, 26, 31, 27, 22, 31, 34, 31, 36, 40, 46, 53, 58, 58, 54, 49, 38, 38, 30, 38, 51, 64, 63,
    72, 70, 70, 73, 77, 90, 93, 93, 99, 99, 99, 99, 92, 90, 86, 88, 88, 83, 89, 93, 97, 90, 90, 90, 91, 97, 91, 81, 86,
    79, 82, 86, 84, 91, 76, 70, 70, 74, 71, 80, 83, 88, 99, 87, 83, 74, 78, 83, 76, 78, 75, 68, 71, 64, 53, 64, 74, 73,
    69, 60, 47, 36, 44, 59, 64, 60, 58, 53, 50, 55, 46, 51, 46, 51, 54, 58, 46, 31, 36, 47, 38, 52, 60, 61, 76, 81, 85,
    73, 73, 71, 78, 68, 63, 63, 73, 68, 61, 50, 38, 35, 38, 33, 33, 26, 20, 27, 18, 29, 27, 33, 37, 25, 11, 3, 1, 1, 1,
    1, 1, 1, 3, 1, 1, 7, 4, 1, 1, 3, 1, 1, 1, 1, 10, 21, 26, 21, 21, 11, 6, 14, 9, 9, 10, 1, 1, 16, 20, 14, 11, 20, 17,
    22, 23, 35, 44, 29, 22, 19, 24, 19, 27, 18, 25, 22, 7, 12, 26, 11, 1, 1, 1, 1, 1, 1, 11, 24, 24, 20, 19, 14, 19, 23,
    20, 27, 19, 19, 23, 15, 22, 17, 20, 5, 4, 12, 12, 14, 4, 15, 9, 6, 1, 1, 1, 16, 1, 7, 10, 10, 18, 17, 22, 35, 28,
    25, 20, 5, 1, 5, 1, 13, 23, 33, 43, 30, 45, 50, 60, 68, 59, 65, 75, 70, 55, 52, 54, 46, 55, 48, 47, 57, 67, 73, 83,
    78, 76, 85, 82, 82, 80, 81, 86, 76, 71, 72, 77, 84, 91, 98, 99, 99, 85, 89, 76, 86, 81, 73, 84, 86, 97, 99, 99, 85,
    70, 56, 51, 63, 67, 70, 65, 64, 62, 70, 66, 79, 66, 64, 51, 52, 62, 74, 68, 83, 82, 82, 82, 82, 87, 77, 72, 69, 58,
    62, 75, 70, 79, 68, 64, 60, 50, 46, 32, 23, 18, 26, 18, 24, 29, 37, 49, 63, 61, 71, 84, 80,
  ],
  [
    35, 28, 21, 17, 19, 14, 8, 16, 11, 16, 7, 1, 8, 4, 10, 9, 5, 17, 17, 14, 17, 7, 1, 1, 9, 13, 17, 18, 23, 11, 15, 16,
    3, 1, 6, 16, 7, 1, 6, 1, 1, 1, 3, 1, 1, 1, 1, 1, 1, 1, 5, 1, 6, 1, 6, 16, 8, 9, 14, 5, 5, 10, 10, 15, 5, 10, 20, 28,
    31, 18, 25, 35, 25, 18, 30, 33, 38, 45, 40, 37, 38, 38, 35, 28, 35, 41, 41, 34, 43, 58, 53, 48, 38, 25, 30, 38, 42,
    32, 30, 15, 15, 1, 1, 1, 6, 1, 15, 9, 14, 15, 15, 24, 27, 18, 3, 4, 1, 1, 1, 1, 14, 17, 24, 20, 22, 11, 6, 19, 27,
    17, 28, 26, 21, 11, 1, 1, 11, 26, 36, 31, 28, 20, 15, 15, 15, 17, 10, 7, 2, 3, 7, 1, 16, 16, 10, 5, 4, 4, 1, 5, 10,
    17, 13, 26, 17, 14, 16, 7, 9, 2, 12, 2, 5, 4, 1, 6, 4, 1, 1, 16, 20, 33, 20, 9, 16, 13, 18, 18, 12, 11, 13, 4, 1, 7,
    18, 23, 27, 28, 35, 39, 41, 26, 29, 39, 39, 53, 51, 41, 48, 57, 51, 55, 46, 47, 40, 47, 39, 34, 39, 44, 46, 52, 45,
    55, 49, 52, 52, 52, 46, 37, 35, 34, 23, 28, 37, 39, 41, 48, 52, 56, 52, 60, 64, 58, 49, 36, 35, 44, 44, 40, 33, 31,
    33, 24, 23, 21, 22, 26, 21, 12, 13, 3, 1, 1, 1, 14, 6, 10, 14, 25, 19, 20, 35, 36, 48, 63, 56, 66, 67, 57, 62, 61,
    73, 83, 92, 88, 74, 77, 72, 84, 98, 84, 79, 92, 90, 88, 93, 93, 91, 82, 80, 78, 73, 81, 81, 89, 75, 70, 67, 68, 58,
    55, 43, 43, 55, 60, 75, 77, 82, 84, 95, 95, 90, 99, 99, 97, 99, 99, 99, 87, 89, 91, 96, 99, 94, 85, 80, 72, 75, 67,
    76, 82, 83, 71, 79, 87, 88, 83, 88, 98, 99, 84, 87, 85, 99, 99, 99, 99, 96, 91, 77, 64, 65, 76, 66, 77, 84, 74, 86,
    88, 82, 75, 63, 63, 77, 73, 82, 82, 77, 80, 85, 70, 63, 68, 80, 67, 75, 83, 97, 89, 83, 81, 95, 99, 99, 95, 89, 99,
    89, 89, 75, 70, 77, 78, 82, 73, 83, 92, 81, 90, 84, 92, 99, 99, 99, 92, 91, 99, 97, 98, 99, 99, 89, 99, 98, 99, 99,
    99, 95, 86, 91, 84, 96, 97, 97, 89, 86, 81, 83, 79, 86, 98, 99, 99, 99, 91, 99, 99, 99, 99, 99, 99, 84, 93, 98, 99,
    99, 92, 88, 90, 97, 92, 90, 87, 87, 77, 72, 63, 56, 61, 74, 84, 94, 99, 99, 99, 94, 99, 99, 91, 97, 98, 97, 94, 87,
    92, 87, 97, 89, 79, 62, 72, 65, 49, 49, 48, 35, 33, 34, 46,
  ],
  [
    46, 45, 57, 59, 73, 78, 74, 69, 59, 70, 61, 72, 77, 75, 75, 77, 72, 69, 73, 70, 67, 61, 51, 37, 23, 29, 19, 24, 28,
    43, 44, 51, 55, 65, 76, 78, 88, 83, 78, 75, 80, 79, 82, 76, 73, 81, 79, 87, 92, 93, 99, 88, 93, 99, 99, 99, 93, 99,
    95, 91, 87, 91, 99, 99, 93, 79, 74, 64, 68, 73, 74, 65, 68, 61, 58, 49, 46, 40, 40, 33, 46, 50, 48, 43, 46, 44, 47,
    42, 40, 38, 29, 30, 30, 32, 26, 38, 43, 43, 48, 50, 55, 53, 48, 47, 59, 48, 48, 39, 33, 44, 34, 32, 33, 26, 21, 33,
    28, 31, 19, 18, 10, 1, 1, 8, 8, 10, 14, 5, 5, 11, 11, 12, 16, 26, 34, 41, 38, 32, 36, 46, 41, 38, 38, 38, 39, 33,
    33, 39, 29, 14, 22, 17, 9, 5, 9, 16, 25, 28, 37, 44, 56, 54, 54, 49, 44, 44, 53, 40, 54, 49, 44, 49, 53, 53, 52, 53,
    40, 49, 62, 73, 75, 70, 65, 80, 80, 79, 89, 89, 82, 82, 85, 90, 96, 82, 75, 77, 82, 75, 82, 70, 60, 68, 60, 61, 60,
    67, 63, 58, 51, 57, 57, 66, 60, 63, 69, 78, 88, 95, 85, 79, 84, 92, 83, 83, 94, 88, 92, 87, 88, 81, 72, 80, 82, 70,
    58, 69, 74, 69, 67, 72, 82, 94, 99, 99, 96, 99, 99, 84, 81, 91, 91, 99, 99, 97, 98, 99, 99, 99, 99, 97, 99, 91, 76,
    80, 70, 66, 76, 77, 72, 77, 70, 74, 66, 62, 58, 54, 45, 40, 44, 44, 48, 46, 49, 52, 43, 42, 41, 51, 65, 62, 73, 69,
    61, 60, 73, 83, 79, 82, 72, 57, 68, 55, 57, 47, 46, 36, 46, 48, 45, 40, 30, 35, 21, 26, 16, 4, 4, 1, 5, 5, 1, 1, 1,
    11, 6, 12, 18, 21, 24, 29, 16, 6, 1, 1, 1, 15, 15, 25, 35, 38, 48, 34, 37, 33, 45, 40, 46, 34, 44, 49, 54, 59, 74,
    71, 76, 66, 65, 65, 52, 50, 41, 34, 39, 33, 41, 52, 49, 58, 47, 38, 38, 36, 37, 33, 34, 35, 42, 47, 57, 54, 51, 36,
    41, 36, 36, 21, 24, 32, 19, 21, 10, 11, 4, 8, 17, 12, 18, 27, 28, 36, 40, 27, 31, 31, 27, 30, 25, 15, 24, 30, 32,
    24, 19, 9, 10, 1, 1, 2, 2, 11, 11, 26, 20, 26, 25, 15, 18, 18, 29, 22, 13, 23, 28, 27, 27, 15, 30, 25, 39, 31, 26,
    12, 15, 28, 28, 41, 41, 34, 44, 43, 50, 55, 55, 45, 39, 40, 31, 34, 24, 18, 21, 11, 8, 4, 9, 9, 5, 1, 1, 1, 7, 17,
    31, 38, 33, 23, 34, 22, 25, 38, 50, 37, 42, 39, 34, 26, 37, 47, 53, 41, 33, 40, 38, 36, 31, 34, 47, 37, 37, 39,
  ],
];

export default randomNumbers;

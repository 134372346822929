import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Popover, Skeleton } from 'antd';

import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { useGetUsersLeaderBoard } from '../../api/leaderBoardApi';
import UserSVG from '../../Components/UI/SVG-Icons/SidebarSVG/UserSVG';
import useMediaQuery from '../../hooks/useMediaQuery';
import { formatNumericValues, priceFormatter, textEllipsis } from '../../utils/utils';

import { LeaderBoardContainer, LeaderBoardSkeleton } from './style';

const LoaderItems = () => {
  return (
    <LeaderBoardSkeleton>
      <Skeleton.Avatar />
      <Skeleton.Avatar />
      <Skeleton.Input size="small" />
    </LeaderBoardSkeleton>
  );
};

const LeaderBoard = () => {
  const { t } = useTranslation();
  const isTab = useMediaQuery(768);
  const navigate = useNavigate();

  const handleCloseLeaderBoard = () => {
    navigate({ hash: null });
  };

  const {
    data: {
      data: { data: { leaderboard: users = [], userRank, userFlag, uId, todayProfit = 0, nickName = '' } = {} } = {},
    } = {},
    isLoading,
  } = useGetUsersLeaderBoard();

  const isUserinLoss = todayProfit < 0;

  const getMedal = (rank) => {
    switch (rank) {
      case 0:
        return 'gold';
      case 1:
        return 'silver';
      case 2:
        return 'bronze';

      default:
        return '';
    }
  };

  return (
    <LeaderBoardContainer isUserInLoss={isUserinLoss}>
      <div className="leader-board__header">
        <div className="title">
          <div>
            <h3>{t('Leader Board')}</h3>
            <h5>{t('of the day')}</h5>
          </div>
          {!isTab ? (
            <span onClick={handleCloseLeaderBoard}>
              <CloseOutlined />
            </span>
          ) : null}
        </div>
        <div className="my-rank">
          <div className="rank">
            {' '}
            {!isLoading ? <img alt="flag" src={userFlag} /> : null}
            {nickName ? textEllipsis(nickName, 20) : `#${uId}`}
            <div className={(isUserinLoss ? 'userInLoss' : 'userInProfit') + ' ' + 'success'}>
              {todayProfit ? (isUserinLoss ? '-' : '+') : null} ${formatNumericValues(todayProfit, false, true)}
            </div>
          </div>
          <hr />
          <div className="position">
            <span> {t('Your Position') + ':' + ' ' + (userRank || '')}</span>
            <div className="popover">
              <Popover content={t('Updates every 24 hours!')}>
                <InfoCircleOutlined />
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <div className="body">
        {isLoading
          ? Array.from({ length: 20 }).map((i) => <LoaderItems key={i}> {i} </LoaderItems>)
          : users.map((user, index) => (
              <div className="leader-board__item" key={'user' + index}>
                <div className={`rank ${getMedal(index)}`}>{index + 1}</div>
                <div className="profile-img">
                  <img alt="flag" src={user?.flag} />
                  <UserSVG fill="#ededed" />
                </div>
                <span title={user?.nickName}>{textEllipsis(user?.nickName, 10)}</span>
                <div className="success m-l-auto" title={user?.profit}>
                  ${priceFormatter(user?.profit)}
                </div>
              </div>
            ))}
      </div>
    </LeaderBoardContainer>
  );
};

export default LeaderBoard;

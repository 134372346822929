import React from 'react';

import { Outlet } from 'react-router-dom';

import useTracking from './useTracking';

const RootLayout = () => {
  useTracking();
  return <Outlet />;
};

export default RootLayout;

import React, { createContext, useContext } from 'react';

import _ from 'lodash';

import { useLoginWithFacebook, useLoginWithGoogle } from '../api/authApi';
import { useUserStore } from '../store/useUserStore';
import openNotification from '../utils/openNotification';

export const OAuthContext = createContext();

export const useOAuthContext = () => useContext(OAuthContext);

const OAuthContextProvider = ({ children }) => {
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const facebookClientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
  const { language } = useUserStore();

  const loginWithGoogle = useLoginWithGoogle();
  const loginWithFacebook = useLoginWithFacebook();
  const onGoogleSuccess = (data, signupParams = {}) => {
    loginWithGoogle.mutate({
      token: data.credential,
      language,
      ...(!_.isEmpty(signupParams) && signupParams),
    });
  };

  const onGoogleError = (error) => {
    openNotification('error', { title: 'Login with google failed.' });
  };

  const responseFacebook = (data) => {
    loginWithFacebook.mutate({
      id: data.id,
      code: data.accessToken,
    });
  };

  const handleFacebookFailure = () => {
    openNotification('error', { title: 'Login with facebook failed.' });
  };
  return (
    <OAuthContext.Provider
      value={{
        googleClientId,
        facebookClientId,
        onGoogleError,
        onGoogleSuccess,
        responseFacebook,
        handleFacebookFailure,
      }}
    >
      {children}
    </OAuthContext.Provider>
  );
};

export default OAuthContextProvider;

import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { Button, Image } from 'antd';

import { ArrowLeftOutlined, FileOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useGetSupportTicket, useGetTicketFile } from '../../../api/supportApi';
import CustomTable from '../../../Components/UI/CustomTable/CustomTable';
import CustomTag from '../../../Components/UI/CustomTag';
import useMediaQuery from '../../../hooks/useMediaQuery';
import routes from '../../../utils/routes';
import AddRequestPage from '../AddRequestPage/AddRequestPage';

import { MobileViewWrapper, SupportDetailContainer, SupportMessageContainer } from './style';

const SupportDetail = () => {
  const { t } = useTranslation();
  const { requestId } = useParams();
  const navigate = useNavigate();
  const isTab = useMediaQuery(768);
  const isMobile = useMediaQuery(480);
  const [previewRef, setPreviewRef] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const downloadFile = useGetTicketFile();
  const { data: { data: supportDetails = {} } = {}, isLoading } = useGetSupportTicket(requestId);

  const handleDrop = () => {
    setIsEditOpen((e) => !e);
  };

  const downloadSupportFile = (id, url, contentType) => {
    if (contentType.includes('/pdf')) {
      window.open(url, '_blank');
    } else {
      setPreviewRef(id);
    }
  };
  const columns = [
    ...(isTab
      ? [
          {
            title: t('All'),
            dataIndex: 'all',
            key: 'all',
            render: (msg) => (
              <MobileViewWrapper>
                <div className="dateTime">{moment(msg?.dateTime).format('MM/DD/YY HH:mm:ss')}</div>
                <div className="single_msg">
                  <div className="message_box ">
                    <div className={`msg ${msg?.message?.repliedBy === 'Admin' ? `admin-msg` : 'user-msg'}`}>
                      <div className="text">{msg?.message?.message}</div>
                      {msg?.message?.repliedBy === 'Admin' ? <i className="label">Response</i> : null}
                    </div>
                  </div>
                </div>
                {msg?.files?.length > 0 ? (
                  <>
                    {previewRef === msg?.files?.[0]?.id ? (
                      <Image
                        preview={{
                          visible: previewRef === msg?.files?.[0]?.id,
                          src: msg?.files?.[0]?.url,
                          onVisibleChange: (value) => {
                            setPreviewRef(null);
                          },
                        }}
                        src={msg?.files?.[0]?.url}
                        width={0}
                      />
                    ) : null}
                    <Button
                      className="p-l-0 p-r-0"
                      loading={downloadFile?.loading?.[msg?.files?.[0]?.id]}
                      onClick={() => {
                        downloadSupportFile(msg?.files?.[0]?.id, msg?.files?.[0]?.url, msg?.files?.[0]?.contentType);
                      }}
                      type="link"
                    >
                      <FileOutlined />
                      {msg?.files?.[0]?.name}
                    </Button>
                  </>
                ) : null}
              </MobileViewWrapper>
            ),
          },
        ]
      : []),
    {
      title: t('Date & Time'),
      dataIndex: 'dateTime',
      key: 'dateTime',
      render: (text) => <div>{moment(text).format('MM/DD/YY HH:mm:ss')}</div>,
      responsive: ['md'],
      width: '15%',
    },
    {
      title: t('Last Message'),
      dataIndex: 'message',
      key: 'message',
      responsive: ['md'],
      render: (msg) => (
        <div className="single_msg">
          <div className="message_box ">
            <div className={`msg ${msg?.repliedBy === 'Admin' ? `admin-msg` : 'user-msg'}`}>
              <div className="text">{msg?.message}</div>
              {msg?.repliedBy === 'Admin' ? <div className="label">{t('Response')}</div> : null}
            </div>
          </div>
        </div>
      ),
      width: '50%',
    },
    {
      title: t('File'),
      key: 'files',
      dataIndex: 'files',
      render: (files) => (
        <div>
          {files?.length > 0 ? (
            <>
              {previewRef === files?.[0]?.id ? (
                <Image
                  preview={{
                    visible: previewRef === files?.[0]?.id,
                    src: files?.[0]?.url,
                    onVisibleChange: (value) => {
                      setPreviewRef(null);
                    },
                  }}
                  src={files?.[0]?.url}
                  width={0}
                />
              ) : null}
              <Button
                className="p-l-0"
                loading={downloadFile?.loading?.[files?.[0]?.id]}
                onClick={() => {
                  downloadSupportFile(files?.[0]?.id, files?.[0]?.url, files?.[0]?.contentType);
                }}
                type="link"
              >
                <FileOutlined />
                {files?.[0]?.name}
              </Button>
            </>
          ) : null}
        </div>
      ),
      responsive: ['md'],
    },
  ];

  const formattedMsgs = supportDetails?.messages?.map((msg) => ({
    all: { dateTime: msg?.createdAt, message: { repliedBy: msg?.repliedBy, message: msg?.message }, files: msg?.files },
    dateTime: msg?.createdAt,
    message: { repliedBy: msg?.repliedBy, message: msg?.message },
    files: msg?.files,
  }));

  return (
    <>
      <AddRequestPage
        isDefaultOpen={isEditOpen}
        isReply={true}
        onChange={(action) => setIsEditOpen(action)}
        requestId={requestId}
      />
      <SupportDetailContainer>
        <div className="title">
          <span onClick={() => navigate(routes.SUPPORT.HOME + routes.SUPPORT.MY_REQUEST)}>
            <ArrowLeftOutlined />
          </span>
          {t('My Request')}
        </div>
        <div className="sub_title">
          <div>
            <div>
              {' '}
              {isMobile ? (
                <span onClick={() => navigate(routes.SUPPORT.HOME + routes.SUPPORT.MY_REQUEST)}>
                  <ArrowLeftOutlined />
                </span>
              ) : null}
              {isMobile ? `#${requestId}` : `${t('Request Number')} : #${requestId}`}
            </div>
            <CustomTag className="tag" tagType={supportDetails?.status}>
              {supportDetails?.status}
            </CustomTag>
          </div>
          <span className="date-time_label">
            {t('Open Date & Time ')} : {moment(supportDetails?.createdAt).format('MM/DD/YYYY HH:mm:ss')}
          </span>
        </div>
      </SupportDetailContainer>
      <SupportMessageContainer>
        {isLoading || supportDetails?.messages?.length ? (
          <CustomTable columns={columns} dataSource={formattedMsgs} loading={isLoading} showHeader={!isTab} />
        ) : null}
        <Button onClick={handleDrop} type="primary">
          {t('Drop Message')}
        </Button>
      </SupportMessageContainer>
    </>
  );
};

export default SupportDetail;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import translationAr from './locales/ar/translation.json';
import translationBn from './locales/bn/translation.json';
import translationEng from './locales/en/translation.json';
import translationEs from './locales/es/translation.json';
import translationFa from './locales/fa/translation.json';
import translationFil from './locales/fil/translation.json';
import translationFr from './locales/fr/translation.json';
import translationHi from './locales/hi/translation.json';
import translationJa from './locales/ja/translation.json';
import translationMs from './locales/ms/translation.json';
import translationPt from './locales/pt/translation.json';
import translationRu from './locales/ru/translation.json';
import translationTh from './locales/th/translation.json';
import translationTr from './locales/tr/translation.json';
import translationUk from './locales/uk/translation.json';
import translationVi from './locales/vi/translation.json';
import translationZh from './locales/zh/translation.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: true,
    lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available
    nsSeparator: '~~',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: { useSuspense: false },
    resources: {
      en: {
        translation: translationEng,
      },
      ar: {
        translation: translationAr,
      },
      bn: {
        translation: translationBn,
      },
      zh: {
        translation: translationZh,
      },
      es: {
        translation: translationEs,
      },
      fil: {
        translation: translationFil,
      },
      fr: {
        translation: translationFr,
      },
      hi: {
        translation: translationHi,
      },
      ja: {
        translation: translationJa,
      },
      ms: {
        translation: translationMs,
      },
      fa: {
        translation: translationFa,
      },
      pt: {
        translation: translationPt,
      },
      ru: {
        translation: translationRu,
      },
      th: {
        translation: translationTh,
      },
      tr: {
        translation: translationTr,
      },
      uk: {
        translation: translationUk,
      },
      vi: {
        translation: translationVi,
      },
    },
    detection: {
      order: ['queryString', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
    // have a common namespace used around the full app
    ns: ['translation'],
    defaultNS: 'translation',
  });

global.__ = i18n.t.bind(i18n);
global.changeLanguage = i18n.changeLanguage.bind(i18n);

export default i18n;

const lastValueTimer = {
  name: 'timer',
  totalStep: 2,
  zLevel: 2,
  createPointFigures: ({ overlay, coordinates, bounding }) => {
    let text = overlay.extendData ?? '';

    const startX = bounding.width - 30;
    const startY = coordinates[0].y + 10;

    return [
      {
        type: 'text',
        attrs: {
          x: startX,
          y: startY,
          text: text,
          align: 'right',
          baseline: 'bottom',
        },
        styles: {
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 5,
          paddingBottom: 5,
        },
        ignoreEvent: true,
      },
    ];
  },
};

export default lastValueTimer;

import { useEffect } from 'react';

import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { useLocation, useNavigationType } from 'react-router-dom';

const useTracking = () => {
  const location = useLocation();
  const navigationType = useNavigationType(); // Detects push/pop navigation

  useEffect(() => {
    const pagePath = location.pathname;

    // Send page view to GA4
    ReactGA.send({ hitType: 'pageview', page: pagePath });

    // Send event to GTM Data Layer
    TagManager.dataLayer({
      dataLayer: { event: 'pageview', page: pagePath },
    });
  }, [location, navigationType]);
};

export default useTracking;

import { useChartAction } from '../../../../../store/useChartAction';
import { useTrade } from '../../../../../store/useTrade';
import { INVESTMENT_TIME_TYPE } from '../../../../../utils/constants';
import { getCandleTimestamps, getTimeLeft } from '../../../../../utils/utils';

const useChartStartEndTimeOverlay = () => {
  const { investmentTime, investmentTimeType } = useTrade();
  const { timeFrame } = useChartAction();

  const investmentCurrentTime = investmentTime * 1000;

  const handleStartEndTradeLine = ({ currentTimeFromServer }) => {
    const candleRange = getCandleTimestamps(Date.now(), timeFrame?.type);
    const candleRangeOfCurrentTime = getCandleTimestamps(investmentCurrentTime, timeFrame?.type);
    if (
      investmentTimeType === INVESTMENT_TIME_TYPE.CURRENT &&
      window?.chartRef?.getOverlayById('startTrade') &&
      window?.chartRef?.getOverlayById('endTrade')
    ) {
      window.chartRef.overrideOverlay({
        id: 'startTrade',
        name: 'startTrade',
        points: [
          {
            timestamp: candleRange?.candleStart,
          },
        ],
        visible: true,
      });
      window.chartRef.overrideOverlay({
        id: 'endTrade',
        name: 'endTrade',
        points: [{ timestamp: candleRangeOfCurrentTime?.candleStart }],
        visible: true,
        extendData: {
          label: `${getTimeLeft(investmentCurrentTime, currentTimeFromServer)}`,
          targetCandleEnd: candleRangeOfCurrentTime?.candleEnd,
        },
      });
    } else if (investmentTimeType === INVESTMENT_TIME_TYPE.CURRENT) {
      window?.chartRef?.createOverlay({
        id: 'startTrade',
        name: 'startTrade',
        points: [{ timestamp: candleRange?.candleStart }],
        visible: false,
      });
      window?.chartRef?.createOverlay({
        id: 'endTrade',
        name: 'endTrade',
        points: [{ timestamp: candleRangeOfCurrentTime?.candleEnd }],
        visible: false,
        extendData: {
          label: `${getTimeLeft(investmentCurrentTime, currentTimeFromServer)}`,
          targetCandleEnd: candleRangeOfCurrentTime?.candleEnd,
        },
      });
    } else if (investmentTimeType === INVESTMENT_TIME_TYPE.NORMAL) {
      window?.chartRef?.removeOverlay('startTrade');
      window?.chartRef?.removeOverlay('endTrade');
    }
  };
  return handleStartEndTradeLine;
};

export default useChartStartEndTimeOverlay;

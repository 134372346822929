import { useTranslation } from 'react-i18next';

import { useGetTimeZones } from '../../api/commonApi';
import CustomSelect from '../UI/CustomSelect/CustomSelect';

const TimezoneSelect = ({ loading, ...props }) => {
  const { t } = useTranslation();
  const { data: { data: timezone = [] } = {}, isLoading: loadingTimeZone } = useGetTimeZones();

  const timeZoneOptions = timezone.map((t) => ({
    value: t,
    label: t,
  }));

  return (
    <CustomSelect label={t('Time Zone')} loading={loadingTimeZone || loading} options={timeZoneOptions} {...props} />
  );
};

export default TimezoneSelect;

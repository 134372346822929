import { ChartTypes, TimeFrameTypes } from '../klinechart/constant';

import { create } from './clearStore';

const initialState = {
  chartType: ChartTypes[0].type,
  timeFrame: TimeFrameTypes[0],
  indicators: [],
  overlays: [],
};

export const useChartAction = create(
  (set, get) => ({
    ...initialState,

    setIndicators: (action, { name, id, settings = [] }) => {
      if (action === 'add') {
        let index = get().indicators.findIndex((indicator) => indicator.id === id);

        if (index > -1) {
          let copyOfIndicatorArray = JSON.parse(JSON.stringify(get().indicators));
          copyOfIndicatorArray[index] = { ...copyOfIndicatorArray[index], settings: settings };
          set({ indicators: copyOfIndicatorArray });
        } else {
          let updatedIndicators = [{ id, name, settings }, ...get().indicators];
          set({ indicators: updatedIndicators });
        }
      }
      if (action === 'remove') {
        let updatedIndicators = get().indicators.filter((ind) => ind.id !== id);
        set({ indicators: updatedIndicators });
      }
    },
    setOverlays: (action, { id, name, points = [], pairId: getActivePairId }) => {
      if (action === 'add') {
        let index = get().overlays.findIndex((overlay) => overlay.id === id);
        if (index > -1) {
          let copyOfOverlayArray = JSON.parse(JSON.stringify(get().overlays));
          copyOfOverlayArray[index] = { ...copyOfOverlayArray[index], points: points };
          set({ overlays: copyOfOverlayArray });
        } else {
          let updatedIndicators = [{ id, name, points, pairId: getActivePairId }, ...get().overlays];
          set({ overlays: updatedIndicators });
        }
      }
      if (action === 'remove') {
        let updatedIndicators = get().overlays.filter((ind) => ind.id !== id);
        set({ overlays: updatedIndicators });
      }
    },
    setChartAction: (data) => {
      set(data);
    },
  }),
  {
    name: 'chartAction', // name of the item in the storage (must be unique)
    initialState,
  },
);

import React from 'react';

import './style.scss';
import { Form } from 'antd';

import TextArea from 'antd/es/input/TextArea';

const CustomTextarea = ({ label = '', status, message, ...props }) => {
  return (
    <div className="modal-form__textarea">
      {label ? <label className="modal-form__textarea-label ">{label}</label> : null}
      <Form.Item help={message} validateStatus={status}>
        <TextArea className="modal-form__textarea-value" rows={4} type="text" {...props} />
      </Form.Item>
    </div>
  );
};

export default CustomTextarea;

import { useEffect, useState } from 'react';

import moment from 'moment';

import { useGetProfileDetails } from '../api/profileApi';
import { useTrade } from '../store/useTrade';
import { INVESTMENT_TIME_TYPE, timezonesList } from '../utils/constants';

const useTimeZone = () => {
  const { data: { data: profileDetails = {} } = {} } = useGetProfileDetails();
  const { setTradeStore, investmentTime, investmentTimeType, investmentCurrentTime } = useTrade();
  const [localCurrentTime, setLocalCurrentTime] = useState(investmentCurrentTime);
  const [localInvestmentTime, setLocalInvestmentTime] = useState(investmentTime);

  const getCurrentTimeZone = timezonesList.find((tz) => tz.utc === profileDetails?.timezone)?.timezone;

  const resetCurrentTradeTime = () => {
    let diffWithCurrentMin = moment().unix() - moment().startOf('minute').unix() < 30;

    setTradeStore({
      investmentCurrentTime: diffWithCurrentMin
        ? moment().startOf('minute').unix()
        : moment().add(1, 'minutes').startOf('minute').unix(),
      investmentTime: diffWithCurrentMin
        ? moment().add(1, 'minutes').startOf('minute').unix()
        : moment().add(2, 'minutes').startOf('minute').unix(),
    });
  };

  const updateCurrentTradeTime = () => {
    setLocalCurrentTime((lc) => {
      const isDiffLessThanParCurrentTime = lc - moment().unix() < -30;

      if (isDiffLessThanParCurrentTime) {
        let newCurrent = moment().add(1, 'minutes').startOf('minute').unix();

        return newCurrent;
      }
      return lc;
    });

    setLocalInvestmentTime((investment) => {
      const isDiffLessThanPar = investment - moment().unix() <= 30;

      if (isDiffLessThanPar) {
        let newInvestmentTime = moment().add(2, 'minutes').startOf('minute').unix();

        return newInvestmentTime;
      }
      return investment;
    });
  };

  useEffect(() => {
    if (getCurrentTimeZone && investmentTimeType === INVESTMENT_TIME_TYPE.CURRENT) {
      resetCurrentTradeTime();
    }
  }, [getCurrentTimeZone]);

  useEffect(() => {
    if (investmentTimeType === INVESTMENT_TIME_TYPE.CURRENT) {
      setTradeStore({
        investmentCurrentTime: localCurrentTime,
      });
    }
  }, [localCurrentTime]);

  useEffect(() => {
    if (investmentTimeType === INVESTMENT_TIME_TYPE.CURRENT) {
      setTradeStore({
        investmentTime: localInvestmentTime,
      });
    }
  }, [localInvestmentTime]);

  return {
    getCurrentTimeZone,
    resetCurrentTradeTime,
    updateCurrentTradeTime,
    utcTime: profileDetails?.timezone,
  };
};

export default useTimeZone;

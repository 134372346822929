import { useTranslation } from 'react-i18next';

import { Avatar, Empty } from 'antd';

import moment from 'moment';

import { useGetNotification, useReadNotification } from '../../api/notificationApi';
import { ReactComponent as RocketSVG } from '../../assets/icons/Rocket.svg';
import { useGetSetData } from '../../hooks/useGetSetData';
import CustomModal from '../UI/CustomModal';
import CustomSpinner from '../UI/CustomSpinner';

import { NotificationCard } from './style';

const NotificationModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const { data: { data: { data: notificationList = [] } = {} } = {}, isPending } = useGetNotification();
  const readNotification = useReadNotification();
  const { fetchProfileDetails } = useGetSetData();

  const handleReadNotification = async (id, notification) => {
    if (notification.clickLink) {
      window.open(notification.clickLink, '_blank');
    }
    await readNotification.mutateAsync(id);
    fetchProfileDetails();
  };

  return (
    <CustomModal footer={null} onCancel={() => setIsOpen(false)} open={isOpen} title={t('Notification')} width={615}>
      {isPending ? (
        <CustomSpinner />
      ) : notificationList.length ? (
        notificationList.map((notification) => (
          <NotificationCard
            bordered={false}
            key={notification.id}
            onClick={() => handleReadNotification(notification?.id, notification)}
          >
            <div className={`dot ${notification?.readAt ? '' : 'active'}`} />
            <div className="notification__container">
              <Avatar icon={<RocketSVG />} shape="square" size="large" style={{ backgroundColor: '#f56a00' }} />
              <div className="notification__container-information">
                <div className="title">
                  <p> {notification?.title}</p>
                  <div className="dateTime">{moment(notification?.createdAt).fromNow()}</div>
                </div>
                <div className="description">{notification?.description}</div>
                {notification?.imageUrl ? <img alt="notification" src={notification?.imageUrl} /> : null}
              </div>
            </div>
          </NotificationCard>
        ))
      ) : (
        <Empty description="No notification available" />
      )}
    </CustomModal>
  );
};

export default NotificationModal;

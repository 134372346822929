import styled from 'styled-components';

import CustomCard from '../../UI/CustomCard';
import CustomModal from '../../UI/CustomModal';

export const UserMenu = styled.div`
  cursor: pointer;
  display: flex;
  padding: 6px 12px;
  font-size: 13px;
  background: var(--color-grey-4);
  height: 100%;
  border-radius: 5px;
  margin-left: 10px;
  align-items: center;
  transition: background 0.5s ease;

  svg {
    path {
      fill: var(--color-text);
    }
  }

  img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  &:hover {
    background-color: var(--color-grey-3);
  }
  > svg:first-child {
    margin-right: 15px;
  }
  .text {
    margin-right: 15px;

    @media screen and (max-width: 768px) {
      display: inline-flex;
    }
    .name {
      color: var(--color-text);
    }
    .balance {
      font-weight: bold;

      @media screen and (max-width: 768px) {
        margin-left: 8px;
      }
    }
  }
`;

export const AccountUserPopup = styled.div`
  width: 450px;

  @media screen and (max-width: 576px) {
    width: 285px;
  }

  .profit__container {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon__wrapper {
      width: 27px;
      height: 27px;
      border-radius: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
  }

  .personal__details {
    word-break: break-all;
  }

  .profile__tabs {
    display: flex;
    flex-direction: column;

    a {
      padding: 5px 0px;
    }

    a:first-child {
      padding-top: 0px;
    }

    .ant-btn-text {
      padding: 5px 0px;
      text-align: left;
      color: var(--color-text-error);
      &:hover {
        color: var(--color-text-error);
      }
    }
  }

  .ant-radio-wrapper {
    .ant-radio {
      margin-right: 15px;
    }
    .ant-radio-inner {
      border-color: var(--color-border);
    }
  }

  .change__currency {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
      width: 22px;
      height: 22px;
    }

    .ant-btn-text {
      color: var(--color-info);
      margin-left: auto;
      padding: 0px;

      &:hover {
        color: var(--color-info);
      }
    }
  }
`;

export const LevelCard = styled(CustomCard)`
  background-color: var(--color-card-grey);
  border-radius: 15px;
  position: relative;

  .card_row {
    display: flex;
    gap: 12px;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    font-family: Poppins;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    font-family: Poppins;
  }
`;

export const LevelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .levels__icon {
    width: 45px;
    height: 45px;
  }

  .active_badge {
    display: none;
  }

  .active_card {
    background-color: var(--color-grey-1) !important;

    .active_badge {
      position: absolute;
      display: flex;
      background-color: var(--color-success-1) !important;
      top: 12px;
      right: 10px;
      padding: 4px 10px;
      border-radius: 5px;
    }
  }
`;

export const LevelModal = styled(CustomModal)`
  .ant-modal-footer {
    display: none !important;
  }
`;

import { useChartContext } from '../context/ChartContext';
import { ChartToolsOptions } from '../klinechart/constant';
import { useChartAction } from '../store/useChartAction';

const useHandleOverlay = () => {
  const { setOverlays } = useChartAction();
  const { setSelectedOverlay } = useChartContext();

  const createToolOverlay = ({ id, name, points, startDrawing = () => {}, getActivePairId }) => {
    let pt;
    if (points) {
      pt = points;
    } else {
      const toolObj = ChartToolsOptions.find((tool) => tool.type === name && tool.steps <= 3);
      if (toolObj) {
        const rangeFromTo = window.chartRef.getVisibleRange();
        const data = window.chartRef.getDataList();

        const arrayOfTrades = data.slice(100, 200);
        let highestTrade = 0;
        let lowestTrade = 0;

        for (let i = 0; i < arrayOfTrades.length; i++) {
          if (highestTrade === 0 || arrayOfTrades[i].high > highestTrade) {
            highestTrade = arrayOfTrades[i].high;
          }
          if (lowestTrade === 0 || arrayOfTrades[i].low < lowestTrade) {
            lowestTrade = arrayOfTrades[i].low;
          }
        }

        pt = [
          {
            timestamp: data.at(-15)?.timestamp,
            value: (highestTrade + lowestTrade) / 2,
            dataIndex: rangeFromTo.realTo - 15,
          },
          {
            timestamp: data.at(-1)?.timestamp,
            value: (highestTrade + lowestTrade) / 2 + ((highestTrade - lowestTrade) / 2) * 0.5,
            dataIndex: rangeFromTo.realTo - 1,
          },
        ];

        if (toolObj.steps === 3) {
          pt.push({
            timestamp: data.at(-1)?.timestamp,
            value: (highestTrade + lowestTrade) / 2 - ((highestTrade - lowestTrade) / 2) * 0.5,
            dataIndex: rangeFromTo.realTo - 1,
          });
        }

        setOverlays('add', { name, id, points: pt, pairId: getActivePairId });
      } else {
        startDrawing();
      }
    }

    window.chartRef.createOverlay({
      id: id,
      name: name,
      ...(pt && { points: pt }),
      onSelected: (e) => {
        setSelectedOverlay({ id: e.overlay.id, name: e.overlay.name, paneId: e.overlay.paneId });
      },
      onDeselected: () => {
        setSelectedOverlay(null);
      },
      onPressedMoveEnd: (e) => {
        setOverlays('add', { name, id, points: e.overlay.points, pairId: getActivePairId });
      },
      onDrawEnd: (e) => {
        setOverlays('add', { name, id, points: e.overlay.points, pairId: getActivePairId });
      },
      onRightClick: () => {
        removeOverlay(id);
      },
    });
  };

  const removeOverlay = (id) => {
    setSelectedOverlay(null);
    window.chartRef.removeOverlay(id);
    setOverlays('remove', { id });
  };

  return {
    createToolOverlay,
    removeOverlay,
  };
};

export default useHandleOverlay;

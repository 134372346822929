import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import openNotification from '../utils/openNotification';

import { NOTIFICATION } from './constant/constant';
import { getApi, postApi } from './utils/utils';

export const useGetNotification = () => {
  return useQuery({
    queryKey: ['notification', 'list'],
    queryFn: () => {
      return getApi(NOTIFICATION.LIST);
    },
    onSuccess: () => {},
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

export const useReadNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => {
      return postApi(NOTIFICATION.READ + id);
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['notification', 'list'],
        stale: true,
      });
    },
    onError: (error) => {
      openNotification('error', { title: error?.response?.data?.message || error?.message });
    },
  });
};

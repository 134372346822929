import { useTranslation } from 'react-i18next';

import SadEmoji from '../../assets/images/sad-emoji.png';
import PublicLayout from '../../Components/Layouts/PublicLayout';
import { PublicWrapper } from '../../styles/style';

import { BlockedCountryContainer } from './style';

const BlockedCountry = () => {
  const { t } = useTranslation();
  return (
    <PublicLayout>
      <PublicWrapper>
        <BlockedCountryContainer>
          <img alt="sad" src={SadEmoji} />
          <h2>{t('Unfortunately, our services not available in your region ')}</h2>
          <p>
            {t('For more detail please contact to our')} <span>{t('support team')}</span>
          </p>
        </BlockedCountryContainer>
      </PublicWrapper>
    </PublicLayout>
  );
};

export default BlockedCountry;

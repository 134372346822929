import { Button, Col, Row } from 'antd';

import { ScaleContainer } from './style';

const ZoomInOut = () => {
  const handleZoomOut = () => {
    window?.chartRef?.zoomAtCoordinate(0.75, {}, 100);
  };
  const handleZoomIn = () => {
    window?.chartRef?.zoomAtCoordinate(1.5, {}, 100);
  };

  return (
    <ScaleContainer>
      <Row gutter={[2, 2]}>
        <Col>
          <Button onClick={handleZoomOut} size="small">
            -
          </Button>
        </Col>
        <Col>
          <Button onClick={handleZoomIn} size="small">
            +
          </Button>
        </Col>
      </Row>
    </ScaleContainer>
  );
};

export default ZoomInOut;

import React, { useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { Badge, Button, Col, Drawer, Popover, Radio, Row } from 'antd';

import { BellOutlined, MoreOutlined, RedoOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { useLogout } from '../../../api/authApi';
import { useGetTradeAccountDetails, useResetDemoBalance, useResetLiveBalance } from '../../../api/tradeApi';
import achiever from '../../../assets/icons/AchieverIcon.svg';
import diamond from '../../../assets/icons/DaimondIcon.svg';
import { ReactComponent as LogoBlackSVG } from '../../../assets/icons/logo-black font.svg';
import { ReactComponent as LogoSVG } from '../../../assets/icons/logo-white font.svg';
import { ReactComponent as LogoSVGMob } from '../../../assets/icons/result.svg';
import standard from '../../../assets/icons/StandardIcon.svg';
import { ACCOUNT_TYPE, LEVELS } from '../../../constants/utils';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useModal from '../../../hooks/useModal';
import { useUserStore } from '../../../store/useUserStore';
import routes from '../../../utils/routes';
import { formatNumericValues } from '../../../utils/utils';
import ChangeCurrency from '../../ChangeCurrency';
import NotificationModal from '../../Notification';
import Sidebar from '../../Sidebar/Sidebar';
import NavigationLink from '../../UI/NavigationLink/NavigationLink';
import './style.scss';
import SecondaryButton from '../../UI/SecondaryButton';
import ArrowSVG from '../../UI/SVG-Icons/ArrowSVG';
import PlusSVG from '../../UI/SVG-Icons/PlusSVG';
import BurgerMenuSVG from '../../UI/SVG-Icons/SidebarSVG/BurgerMenuSVG';

import { AccountUserPopup, LevelCard, LevelModal, LevelsContainer, UserMenu } from './style';

const PrivateNavbar = () => {
  const isTab = useMediaQuery(769);
  const logout = useLogout();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userDetails = useUserStore();
  const queryClient = useQueryClient();
  const { data: profileDetails } = queryClient.getQueryData(['profile', 'details']) || {};

  const { data: { data: { data: tradingAccounts = [] } = {} } = {} } = useGetTradeAccountDetails();
  const { isShowing: isChangeCurrency, toggle: setChangeCurrency } = useModal(false);
  const { isShowing: isNotificationOpen, toggle: setIsNotificationOpen } = useModal(false);
  const { mutate: resetBalance } = useResetDemoBalance();
  const { mutate: resetLiveBalance } = useResetLiveBalance();

  const activeTradingAccount = tradingAccounts.find((acc) => acc.isActive);
  const liveTradingAccount = tradingAccounts.find((acc) => acc.type === 'Live');

  const [isSideBarOpen, setIsSidebarOpen] = useState(false);
  const [isLevelModalOpen, setIsLevelModalOpen] = useState(false);

  const { theme } = useUserStore();

  const { pathname } = useLocation();

  const logoutHandler = () => {
    logout.mutate({});
  };

  const handleSwitchTradingAccount = (type) => {
    if (type === ACCOUNT_TYPE.LIVE) {
      navigate(routes.TERMINAL.HOME);
    } else if (type === ACCOUNT_TYPE.DEMO) {
      navigate(routes.DEMO_TRADE);
    }
  };

  const MoreContent = useCallback(
    () => (
      <>
        <NavigationLink to={!profileDetails?.sponsored ? routes.PROFILE.HOME + routes.PROFILE.DEPOSIT : null}>
          <Button block icon={<PlusSVG />} type="primary">
            {t('Deposit')}
          </Button>
        </NavigationLink>
        <NavigationLink to={routes.PROFILE.HOME + routes.PROFILE.WITHDRAWAL}>
          <SecondaryButton block>{t('Withdrawal')}</SecondaryButton>
        </NavigationLink>
      </>
    ),
    [profileDetails?.sponsored, t],
  );

  const moreDropDown = (
    <>
      <MoreContent />
      {/* <SpinningWheel /> */}
    </>
  );

  const getLevelIcon = (level) => {
    const updateLevel = _.upperCase(level);

    if (updateLevel === LEVELS.Standard) {
      return standard;
    }
    if (updateLevel === LEVELS.Premium) {
      return achiever;
    }
    if (updateLevel === LEVELS.Vip) {
      return diamond;
    }
    return standard;
  };

  const levels = [
    {
      icon: standard,
      title: t(LEVELS.Standard),
      description: t('Enjoy the first level with minimal balance!'),
    },
    {
      icon: achiever,
      title: t(LEVELS.Premium),
      description: (
        <>
          {t('Balance from $1,000.00')} <br /> {t('Enables fast withdrawal.')}
        </>
      ),
    },
    {
      icon: diamond,
      title: t(LEVELS.Vip),
      description: (
        <>
          {t('Balance from $3,000.00')} <br /> {t('Enables fastest withdrawal.')}
        </>
      ),
    },
  ];

  const UserAccount = (
    <AccountUserPopup>
      <Row className="details__container">
        <Col sm={16} xs={24}>
          <Row>
            <Col span={24}>
              <div className="personal__details">
                <h4>{userDetails?.user?.email}</h4>
              </div>
            </Col>
            {tradingAccounts
              .sort((a, b) => b.type.localeCompare(a.type))
              .map((account, index) => (
                <Col className="trading__account" key={`account_${index}`} span={24}>
                  <Radio checked={account.isActive} onClick={() => handleSwitchTradingAccount(account.type)}>
                    {' '}
                    <h4>{t(`${account.type} Account`)}</h4>{' '}
                    <h4>{`${formatNumericValues(+account.balance || 0)} ${account.currency}`}</h4>{' '}
                  </Radio>
                  {(account.type === 'Live' && !!profileDetails?.sponsored) || account.type === 'Demo' ? (
                    <div
                      className="refresh-balance"
                      onClick={() => (account.type === 'Live' ? resetLiveBalance({}) : resetBalance({}))}
                    >
                      <RedoOutlined />
                    </div>
                  ) : null}
                </Col>
              ))}
            <Col className="change__currency" span={24}>
              <img alt="Currency Country" src={profileDetails?.currencyFlag} />
              {t('Currency')}: {profileDetails?.currency}
              <Button onClick={setChangeCurrency} type="text">
                {t('Change')}
              </Button>
            </Col>
            <Col>
              <div className="profit__container" onClick={() => setIsLevelModalOpen(true)}>
                <div className="icon__wrapper">
                  <img alt="level" src={getLevelIcon(liveTradingAccount?.level)} />
                </div>
                <div>
                  <h4>{t(LEVELS[liveTradingAccount?.level])}</h4>
                  {/* <h4>{t('X_Profit', { profit: '100' })}</h4> */}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm={8} xs={24}>
          <div className="profile__tabs">
            <NavLink to={routes.PROFILE.HOME + routes.PROFILE.DEPOSIT}>{t('Deposit')}</NavLink>
            <NavLink to={routes.PROFILE.HOME + routes.PROFILE.WITHDRAWAL}>{t('Withdrawal')}</NavLink>
            <NavLink to={routes.PROFILE.HOME + routes.PROFILE.TRANSACTION}>{t('Transaction')}</NavLink>
            <NavLink to={routes.PROFILE.HOME + routes.PROFILE.TRADES}>{t('Trades')}</NavLink>
            <NavLink to={routes.PROFILE.HOME + routes.PROFILE.ACCOUNT}>{t('Account')}</NavLink>
            <Button loading={logout.isPending} onClick={logoutHandler} style={{ display: 'block' }} type="text">
              {t('Logout')}
            </Button>
          </div>
        </Col>
      </Row>
    </AccountUserPopup>
  );

  return (
    <header className="header">
      {isTab ? (
        <Drawer open={isSideBarOpen} placement="left" width={50}>
          <Sidebar setIsSidebarOpen={setIsSidebarOpen} />
        </Drawer>
      ) : null}
      <div className="header__brand">
        {isTab ? (
          <div onClick={() => setIsSidebarOpen(true)}>
            <BurgerMenuSVG />
          </div>
        ) : null}
        <NavigationLink to={routes.TERMINAL}>
          {isTab ? (
            <LogoSVGMob />
          ) : theme === 'light' ? (
            <LogoBlackSVG height={40} width={200} />
          ) : (
            <LogoSVG height={40} width={200} />
          )}
        </NavigationLink>
      </div>
      <div className="header__container">
        <div className="header__usermenu">
          {/* {!isTab ? <SpinningWheel /> : null} */}
          {isNotificationOpen ? (
            <NotificationModal isOpen={isNotificationOpen} setIsOpen={setIsNotificationOpen} />
          ) : null}
          <Badge count={profileDetails?.unreadNotifications}>
            <SecondaryButton
              className="notification__icon"
              icon={<BellOutlined />}
              onClick={setIsNotificationOpen}
              size={isTab ? 'small' : 'medium'}
            />
          </Badge>
          <Popover
            arrow={false}
            content={UserAccount}
            key={pathname}
            placement="bottomRight"
            rootClassName="user__account"
            trigger="hover"
            zIndex={99}
          >
            <UserMenu>
              <img alt="level" src={getLevelIcon(liveTradingAccount?.level)} />
              <div className="text">
                <div className="name text-wrap">
                  {t(`${activeTradingAccount?.type || 'Demo'}${isTab ? '' : ' Account'}`)}
                </div>
                <div className="balance">{`${formatNumericValues(+activeTradingAccount?.balance || 0)} ${
                  activeTradingAccount?.currency || ''
                }`}</div>
              </div>
              <ArrowSVG />
            </UserMenu>
          </Popover>
          {isChangeCurrency ? <ChangeCurrency isOpen={isChangeCurrency} setIsOpen={setChangeCurrency} /> : null}
        </div>
        <div className="header__navlinks">
          <MoreContent />
          <Popover
            arrow={false}
            content={moreDropDown}
            placement="bottomRight"
            rootClassName="deposit_withdrawal"
            trigger="click"
          >
            <SecondaryButton className="moreButton" icon={<MoreOutlined />} size="small" />
          </Popover>
        </div>
      </div>
      {isLevelModalOpen ? (
        <LevelModal onCancel={() => setIsLevelModalOpen(false)} open={true} title={t('Account Levels')} width={400}>
          <LevelsContainer>
            {levels?.map((item) => {
              return (
                <LevelCard className={item.title === liveTradingAccount?.level ? 'active_card' : ''} key={item?.title}>
                  <div className="card_row">
                    <div>
                      <img alt={item?.title} className="levels__icon" src={item?.icon} />
                    </div>
                    <div>
                      <div className="title">{item?.title}</div>
                      <div className="description">{item?.description}</div>
                    </div>
                  </div>
                  <Badge className="active_badge">Active</Badge>
                </LevelCard>
              );
            })}
          </LevelsContainer>
        </LevelModal>
      ) : null}
    </header>
  );
};

export default PrivateNavbar;

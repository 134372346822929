import React, { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Form, Input, Button } from 'antd';

import TextArea from 'antd/es/input/TextArea';

import { useContactForm } from '../../api/commonApi';
import CustomUpload from '../../Components/UI/CustomUpload/CustomUpload';
import FacebookSVG from '../../Components/UI/SVG-Icons/FacebookSVG';
import InstagramSVG from '../../Components/UI/SVG-Icons/InstagramSVG';
import PinSVG from '../../Components/UI/SVG-Icons/PinSVG';
import AirplaneSVG from '../../Components/UI/SVG-Icons/SidebarSVG/AirplaneSVG';
import TwitterSVG from '../../Components/UI/SVG-Icons/TwitterSVG';
import './style.scss';
import { UnauthorizedWrapper } from '../../styles/style';

const { useForm } = Form;

const ContactUsPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [form] = useForm();
  const contactUs = useContactForm();
  const [showForm, setShowForm] = useState(false);
  const [accountRecovery, setAccountRecovery] = useState(false);
  const [formMessage, setFormMessage] = useState(false);
  useEffect(() => {
    if (formMessage) {
      setTimeout(() => {
        setFormMessage(false);
      }, 5000);
    }
  }, [formMessage]);

  const onFinish = async (values) => {
    const { accountRecovery, files, accountEmail, contactEmail, message } = values;

    const type = accountRecovery ? 'account_recovery' : 'other_problem';

    const payload = {
      type: type,
      files: files?.[0]?.originFileObj,
      accountEmail: accountEmail || '',
      contactEmail: contactEmail || '',
      message: message || '',
    };

    const response = await contactUs.mutateAsync(payload);

    if (response.status === 200) {
      setFormMessage(true);
      form.resetFields();
    }
  };

  return (
    <UnauthorizedWrapper>
      <Helmet>
        <title>{t('Contact us')}</title>
      </Helmet>
      <div className="contact-us">
        <div className="contact-us__header__wrapper">
          <div className="contact-us__text-wrapper">
            <h2 className="contact-us__text-wrapper__title">{t('Contact us')}</h2>
            <p className="contact-us__text-wrapper__subtitle">
              {t(
                'If you are a current customer with an active account on our platform, we encourage you to reach out to our dedicated support team through the Support section in your profile.',
              )}
            </p>
            <p className="contact-us__text-wrapper__subtitle">
              {t(
                'Should you encounter challenges with logging in or require assistance in recovering access, contact us today.',
              )}
            </p>
          </div>
          <div className="contact-us__address-wrapper">
            <div className="address-block">
              <div className="address-block__text">
                <div className="address-block__text__img">
                  <PinSVG />
                </div>
                <div>
                  <b>MartexTrade</b>
                  <p className="address-block__text__text2">
                    {' '}
                    Ground Floor, The Sotheby Building, <br /> Saint Lucia P.O. Box 838
                  </p>
                </div>
              </div>
              <div className="address-block__social">
                <NavLink className="svg-link" target="_blank" to="https://t.me/MartexTradeOfficial">
                  <AirplaneSVG />
                </NavLink>
                <FacebookSVG />
                <InstagramSVG />
                <TwitterSVG />
              </div>
            </div>
          </div>
        </div>
        <div className="contact-us__topic-wrapper">
          <h2 className="contact-us__topic-wrapper__title">{t('Choose a topic')}</h2>
          <div className="contact-us__topic-wrapper__buttons-block">
            <button
              className={`topic-button ${accountRecovery ? 'active' : ''}`}
              onClick={() => {
                setAccountRecovery(true);
              }}
              type="button"
            >
              {t('Account recovery')}
            </button>
            <button
              className={`topic-button ${accountRecovery ? '' : 'active'}`}
              onClick={() => {
                setAccountRecovery(false);
                setShowForm(false);
              }}
              type="button"
            >
              {t('Other problem')}
            </button>
          </div>

          {accountRecovery ? (
            <>
              <div className="contact-us__topic-wrapper__information-block">
                <div className="information__item">
                  <h2 className="information__item__title">
                    {t('We do not facilitate the recovery of closed accounts.')}
                  </h2>
                  <p className="information__item__description">
                    {t(
                      'Once an account is closed, restoration is not available for any reason. We appreciate your understanding of this policy.',
                    )}
                  </p>
                </div>
                <div className="information__item">
                  <h2 className="information__item__title">{t('What if the account is closed by mistake:')}</h2>
                  <p className="information__item__description">
                    {t(
                      'In the event of an account closure by mistake, please contact our support team immediately for further assistance. We are committed to resolving any inadvertent closures and ensuring a swift resolution to address your concerns.',
                    )}
                  </p>
                </div>
              </div>
              <button
                className={`open-form__button ${showForm ? 'active' : ''}`}
                onClick={() => setShowForm((prev) => !prev)}
                type="button"
              >
                {t('Open Contact Form')}
              </button>
            </>
          ) : null}
        </div>

        {accountRecovery && showForm ? (
          <div className="contact-us__contact-form">
            <h2 className="contact-us__contact-form__title">{t('Fill out the support form for help')}</h2>
            <Form autoComplete="off" form={form} initialValues={{ remember: true }} name="basic" onFinish={onFinish}>
              <Form.Item
                label=""
                name="accountEmail"
                rules={[{ type: 'email', message: t('Please enter valid contact email!') }]}
              >
                <Input className="contact-input input" id="1" placeholder={t('Enter your account email')} />
              </Form.Item>
              <Form.Item
                label=""
                name="contactEmail"
                rules={[
                  { required: true, message: t('Please enter valid contact email!') },
                  { type: 'email', message: t('Please enter your contact email!') },
                ]}
              >
                <Input className="contact-input input" id="2" placeholder={t('Enter your email')} />
              </Form.Item>
              <Form.Item label="" name="message" rules={[{ required: true, message: t('Please input your message!') }]}>
                <TextArea className="contact-textarea input" placeholder={t('Your message')} rows={4} />
              </Form.Item>
              <CustomUpload />

              <Form.Item wrapperCol={{}}>
                <Button className="send-request__button" htmlType="submit" loading={contactUs.isPending} type="primary">
                  {t('Send Request')}
                </Button>
                {formMessage ? <div className="formMessage">{t('Your request sent successfully')}</div> : null}
              </Form.Item>
            </Form>
          </div>
        ) : null}
        {!accountRecovery ? (
          <div className="contact-us__contact-form">
            <h2 className="contact-us__contact-form__title">{t('Fill out the support form for help')}</h2>
            <Form autoComplete="off" form={form} initialValues={{ remember: true }} name="basic" onFinish={onFinish}>
              <Form.Item
                label=""
                name="accountEmail"
                rules={[{ type: 'email', message: t('Please enter valid contact email!') }]}
              >
                <Input className="contact-input input" id="1" placeholder={t('Enter your account email')} />
              </Form.Item>
              <Form.Item
                label=""
                name="contactEmail"
                rules={[
                  { required: true, message: t('Please enter valid contact email!') },
                  { type: 'email', message: t('Please enter your contact email!') },
                ]}
              >
                <Input className="contact-input input" id="2" placeholder={t('Enter your email')} />
              </Form.Item>
              <Form.Item label="" name="message" rules={[{ required: true, message: t('Please input your message!') }]}>
                <TextArea className="contact-textarea input" placeholder={t('Your message')} rows={4} />
              </Form.Item>
              <CustomUpload />
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button className="send-request__button" htmlType="submit" loading={contactUs.isPending} type="primary">
                  {t('Send Request')}
                </Button>
                {formMessage ? <div className="formMessage">{t('Your request sent successfully')}</div> : null}
              </Form.Item>
            </Form>
          </div>
        ) : null}
      </div>
    </UnauthorizedWrapper>
  );
};

export default ContactUsPage;

import React from 'react';

import { useTranslation } from 'react-i18next';

import SomethingWentWrongIcon from '../../assets/images/505Error.svg';
import CustomAnnouncement from '../../Components/UI/CustomAnnouncement/CustomAnnouncement';
const SomethingWentWrong = () => {
  const { t } = useTranslation();
  return (
    <CustomAnnouncement.StaticAnnouncement
      actionButton={() => window.location.reload()}
      header={t('Error!')}
      img={SomethingWentWrongIcon}
      text={t('Please refresh the page and try again!')}
    />
  );
};

export default SomethingWentWrong;

import { THEME } from '../Pages/TerminalSettings/constant';

import { create } from './clearStore';

const initialState = {
  user: {},
  theme: THEME.DARK,
  notificationAlert: true,
  token: {},
  language: 'English',
  isLoggedIn: false,
};

export const useUserStore = create(
  (set) => ({
    ...initialState,
    updateUser: (user) => set({ user }),
    setTheme: (theme) => set({ theme }),
    setNotificationAlert: (notificationAlert) => set({ notificationAlert }),
    addToken: (token) => set({ token, isLoggedIn: true }),
    setLanguage: (language) => set({ language }),
  }),
  {
    name: 'userData',
    initialState,
    excludedKeys: ['language'],
  },
);
